import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Col, Label, Row, Spinner } from 'reactstrap';

import { LangServices } from '@lainaedge/platformshared';
import { ChangePasswordResult } from '@lainaedge/platformshared/src/types/ChangePasswordResult';
import { useAuth } from 'Common/context/AuthContext';
import { participantResetPasswordRequest, resetPasswordWithCode } from 'Common/services';
import { IParticipantForgetPWFormFields, IResetPWFormFields } from 'Common/types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import toastr from 'toastr';
import * as Yup from 'yup';

const langService = LangServices.instance();

/**
 * Participant/ForgotPassword component.
 *
 * @remarks
 * Page where participant can reset his password
 *
 * @component ForgotPassword
 * @category Page
 */
const ForgetPasswordPage = (): JSX.Element =>
{
  const navigate = useNavigate();
  const { setError, success, setSuccess, clearState } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
  const [isNextStep, setIsNextStep] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [pwdError, setPwdError] = useState<string>('');

  async function handleValidSubmit(values: Record<string, any>)
  {
    clearState();
    SetIsSubmitting(true);
    let res1: boolean;
    let res2: ChangePasswordResult;
    if (!isNextStep)
    {
      setEmail(values.email);
      res1 = await participantResetPasswordRequest(values as IParticipantForgetPWFormFields);
      if (res1)
      {
        setIsNextStep(true);
        setSuccess(langService.Translate('A verification code has been sent to the email.'));
      } else
      {
        setError(langService.Translate('Username or email is not correct'));
      }
    } else
    {
      setPwdError('');
      if (values.password !== values.password_confirmation)
      {
        setPwdError(langService.Translate('Confirm Password does not match'));
        SetIsSubmitting(false);
        return;
      }
      res2 = await resetPasswordWithCode({
        ...values,
        verificationCode: parseInt(values.verificationCode),
      } as IResetPWFormFields);
      if (res2.success)
      {
        setSuccess(langService.Translate('The password has been reset.'));
        setTimeout(() =>
        {
          navigate('/login');
          toastr.success(langService.Translate('The password has been reset.'), 'Success');
        }, 1000);
      } else
      {
        setPwdError(langService.Translate(res2.message!));
      }
    }
    SetIsSubmitting(false);
  }

  const handleClearState = () =>
  {
    clearState();
  };

  const gotoPrevStep = () =>
  {
    clearState();
    setEmail('');
    setPwdError('');
    setIsNextStep(false);
  };

  return (
    <div>
      {pwdError && pwdError ? (
        <Alert color="danger" style={{ marginTop: '13px' }}>
          {pwdError}
        </Alert>
      ) : null}
      {success ? (
        <Alert color="success" style={{ marginTop: '13px' }}>
          {success}
        </Alert>
      ) : null}

      {!isNextStep ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(langService.Translate('Enter Email')),
          })}
          onSubmit={(values) =>
          {
            handleValidSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="form-horizontal">
              <h3 className="text-center mb-5">{langService.Translate('Forgot Password')}?</h3>
              <div className="mb-3">
                {langService.Translate('Enter your username below and we will send you password reset link')}
              </div>
              <div className="form-group">
                <Label for="email" className="form-label">
                  {langService.Translate('Email')}
                </Label>
                <Field
                  name="email"
                  type="input"
                  id="email"
                  required
                  placeholder={langService.Translate('Enter Email')}
                  className={
                    'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </div>

              <Row className="form-group">
                <Col className="text-right">
                  <button
                    className="btn btn-login btn-block w-md waves-effect waves-light save-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {langService.Translate('Next')}
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            email: email,
            verificationCode: '',
            password: '',
            password_confirmation: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required(langService.Translate('Enter Email')),
            verificationCode: Yup.string().required(langService.Translate('Enter Code')),
            password: Yup.string().required(langService.Translate('Enter Password')),
            password_confirmation: Yup.string().required(langService.Translate('Enter Password Confirmation')),
          })}
          onSubmit={(values) =>
          {
            handleValidSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="form-horizontal">
              <h3 className="text-center mb-5">{langService.Translate('Set Password')}</h3>

              <div className="form-group">
                <Label for="email" className="form-label">
                  {langService.Translate('Email')}
                </Label>
                <Field
                  name="email"
                  type="input"
                  id="email"
                  required
                  disabled
                  placeholder={langService.Translate('Enter Email')}
                  value={email}
                  className={
                    'form-control' + (errors.email && touched.email ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <Label for="verificationCode" className="form-label">
                  {langService.Translate('Code')}
                </Label>
                <Field
                  name="verificationCode"
                  type="input"
                  required
                  placeholder={langService.Translate('Enter Code')}
                  className={
                    'form-control' +
                    (errors.verificationCode && touched.verificationCode ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage
                  name="verificationCode"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <Label for="password" className="form-label">
                  {langService.Translate('New Password')}
                </Label>
                <Field
                  name="password"
                  type="password"
                  required
                  placeholder={langService.Translate('Enter new password')}
                  className={
                    'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                  }
                />
                <ErrorMessage name="password" component="div" className="invalid-feedback" />
              </div>

              <div className="form-group">
                <Label for="password_confirmation" className="form-label">
                  {langService.Translate('Confirm Password')}
                </Label>
                <Field
                  name="password_confirmation"
                  type="password"
                  required
                  placeholder={langService.Translate('Enter confirm password')}
                  className={
                    'form-control' +
                    (errors.password_confirmation && touched.password_confirmation
                      ? ' is-invalid'
                      : '')
                  }
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <Row className="form-group">
                <Col className="text-right">
                  <button
                    className="btn btn-light-login w-md waves-effect waves-light save-btn mr-2"
                    disabled={isSubmitting}
                    onClick={gotoPrevStep}
                    type="button"
                  >
                    {langService.Translate('Back')}
                  </button>
                  <button
                    className="btn btn-login w-md waves-effect waves-light save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {langService.Translate('Reset')}
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ForgetPasswordPage;
