import React from 'react';
import { InputGroup } from 'reactstrap';

import { StepForm } from '@lainaedge/platformshared';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';

/**
 * YearInput component
 *
 * @component YearInput
 * @category FormElements
 */
export default class YearInput extends FormInput
{
  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeText = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = e.target.value;
    this.setValue(field, value);
  };

  /**
   * Renders YearInput class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    let className = 'form-control';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';
    if (this.state.myAlign === 'right') className += ' text-right';

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <InputGroup>
            <input
              className={className}
              type="number"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onKeyDown={(event) =>
              {
                return event.key === 'e' ? false : true;
              }}
              onChange={(e: any) =>
              {
                if (e.target.value.length > 4) return false;
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else
                {
                  this.setState({ myFieldValue: e.target.value });
                }
              }}
              onBlur={() =>
              {
                if (this.isEditFieldOnModal())
                {
                  if (this.props.formProps.edit_values[field.field].trim().length === 4)
                    this.props.formProps.handleChangeEditValues(
                      field,
                      this.props.formProps.edit_values[field.field].trim(),
                    );
                  else
                  {
                    this.props.formProps.handleChangeEditValues(field, '');
                  }
                } else
                {
                  if (this.state.myFieldValue.length === 4 || this.state.myFieldValue === '')
                    this.setValue(field, this.state.myFieldValue);
                  else this.setValue(field, '');
                }
              }}
            />
          </InputGroup>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
