import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { NavigateFunction } from 'react-router-dom';

import { DataRecord, LangServices, LockManager, LogicEngine, StepTable } from '@lainaedge/platformshared';
import { GridSizeChangedEvent, RowClassParams, RowNode, RowStyle } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/shared/changeDetectionService';
import moment from 'moment';
import toastr from 'toastr';

import LockSwitch from '../../TableElements/LockSwitch';

import 'toastr/build/toastr.min.css';
import 'ag-grid-enterprise';

const langService = LangServices.instance();

/**
 * Props for [[`PageGrid`]] component
 */
export interface PageGridProps
{
  /** Step data.*/
  step: StepTable;
  navigate: NavigateFunction;
}

/**
 * Used for dynamic key value objects.
 */
export interface LooseObject
{
  [key: string]: any;
}

/**
 * Used for state for [[`PageGrid`]] component
 */
export interface PageGridState extends LooseObject
{
  /** Represents a field used for sort */
  sortKey: string;
  sortKeyIndex: number;
  /** Represents the sort direction  */
  sortDirection: string;
}

/**
 * Used in [[`PageGrid`]] component
 */
export interface CellValue
{
  sortValue: any;
  /** String value representing text of the cell. */
  text: string;
  /** Optional, path for redirection. */
  link?: string;
  /** Optional, cell icon. */
  icon?: string;
  /** Cell alignment. */
  align: string;
  /** Background color. */
  bgColor?: string;
}

/**
 * PageGrid component
 *
 * @component PageGrid
 * @category PageElements
 */
export class PageGrid extends Component<PageGridProps> {
  /**
   * Uses [[`PageFormState`]] interface as state object.
   */
  state: PageGridState = {
    sortKey: '',
    sortKeyIndex: -1,
    sortDirection: '',
  };

  /* Return a valid column alignment if one is passed in */
  getAlign(rawAlign: string): 'left' | 'right' | 'center' | 'justify'
  {
    if (rawAlign && rawAlign.toLowerCase() === 'left') return 'left';
    if (rawAlign && rawAlign.toLowerCase() === 'right') return 'right';
    if (rawAlign && rawAlign.toLowerCase() === 'center') return 'center';
    if (rawAlign && rawAlign.toLowerCase() === 'justify') return 'justify';
    return 'left';
  }

  /**
   * Used to toggle task lock status.
   *
   * @param index - number. toggles the lock status of nth row
   * @returns Promise (boolean)
   */
  toggleTaskLockStatus = async (index: any, old_status: boolean): Promise<boolean> =>
  {
    const records = this.props.step.dataset.getRecords();
    const record: DataRecord | undefined = records[index];
    const status = !old_status;

    if (record && record.data && record.tableName)
    {
      const logicRef = new LogicEngine();
      LogicEngine.currentGroups = ['DataMonitor'];

      const l: LockManager = new LockManager(logicRef);

      if (status)
      {
        const result = await l.lockRecord(
          record.tableName,
          record.data.id,
          'Locked with toggle button',
          false,
        );

        if (
          result === LockManager.LockResult.Locked ||
          result === LockManager.LockResult.AlreadyLocked
        )
        {
          toastr.success(langService.Translate('Task Lock Success'), 'Success');
          return status;
        } else
        {
          toastr.error(langService.Translate('Task Lock Failure'), 'Failure');
        }
      } else
      {
        const result = await l.unlockRecord(
          record.tableName,
          record.data.id,
          'Unlocked by Edit Fields',
          false,
        );

        if (
          result === LockManager.LockResult.Unlocked ||
          result === LockManager.LockResult.AlreadyUnlocked
        )
        {
          toastr.success(langService.Translate('Task Unlock Success'), 'Success');
          return status;
        } else
        {
          toastr.error(langService.Translate('Task Unlock Failure'), 'Failure');
        }
      }
    }
    return record?.data.lock_status;
  };

  cellRendererFunc = (props: any) =>
  {
    if (props.value?.type == 'action')
    {
      const cellData = props.value.cellData;
      /** Render action buttons */
      const wrapper = document.createElement('div');
      ReactDOM.render(
        <a
          onClick={() => this.handleClickLink(cellData.link!)}
          style={{ width: '100%' }}
          className="btn btn-primary btn-sm w-xs"
        >
          {cellData.text}
          {cellData.icon && <i style={{ paddingLeft: '10px' }} className={cellData.icon} />}
        </a>,
        wrapper,
      );
      return wrapper;
    } else if (props.value?.type == 'toggle')
    {
      const rowIndex = props.value.rowIndex;
      const step = this.props.step;
      const records = step.dataset.getRecords();
      /** Render Lock Toggle button */
      const wrapper = document.createElement('div');
      ReactDOM.render(
        <LockSwitch
          record={records[rowIndex]}
          index={rowIndex}
          toggleTaskLockStatus={this.toggleTaskLockStatus}
        />,
        wrapper,
      );
      return wrapper;
    }
    return props.value;
  };

  onGridReady = async (params: any) =>
  {
    this.resizeGridColumns(params);
  };

  /** Resize grid columns to auto fit within the screen width */
  resizeGridColumns = (params: GridSizeChangedEvent) =>
  {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    const allColumnIds: any[] = [];
    const columnApi = params.columnApi;
    if (columnApi)
    {
      columnApi.getAllColumns()?.forEach((column: any) =>
      {
        if (column.getId() === 'Participant ID' || column.getId() === 'PID')
        {
          allColumnIds.push(column.getId());
        }
      });
      columnApi.autoSizeColumns(allColumnIds, false);
    }
  };

  /** On screen width change, readjust the grid columns width */
  onGridViewportResize = (params: GridSizeChangedEvent) =>
  {
    this.resizeGridColumns(params);
  };

  getRowStyles = (params: RowClassParams) =>
  {
    const step = this.props.step;
    const formattedData = step.getFormattedData();

    if (params && params.node)
    {
      const node: RowNode = params.node;
      const rowIndex = node.rowIndex ?? 0;

      if (formattedData[rowIndex][0].bgColor === 'Active')
      {
        return { background: '#dadada' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Success')
      {
        return { background: '#c8e5cb' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Default')
      {
        return { background: '#ffffff' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Primary')
      {
        return { background: '#bfd9fe' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Warning')
      {
        return { background: '#fbefba' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Info')
      {
        return { background: '#c5e5eb' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Danger')
      {
        return { background: '#efc7cb' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Secondary')
      {
        return { background: '#dddee2' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Light')
      {
        return { background: '#fefefe' } as RowStyle;
      } else if (formattedData[rowIndex][0].bgColor === 'Dark')
      {
        return { background: '#c7c9ca' } as RowStyle;
      }
    }
    return {};
  };

  handleClickLink = (link: string) =>
  {
    this.props.navigate(link);
  };

  public render(): JSX.Element
  {
    const step = this.props.step;

    const formattedData = step.getFormattedData();
    // const records = step.dataset.getRecords();

    const rowData = formattedData.map((row: CellValue[], rowIndex: number) =>
    {
      const obj: LooseObject = {};
      row.forEach((cellData: CellValue, colIndex: number) =>
      {
        if (step.columns[colIndex].is_action)
        {
          obj[step.columns[colIndex].text] = {
            type: 'action',
            cellData: cellData
          };
          // /** Render action buttons */
          // const wrapper = document.createElement('div');
          // ReactDOM.render(
          //   <a
          //     onClick={() => this.handleClickLink(cellData.link!)}
          //     style={{ width: '100%' }}
          //     className="btn btn-primary btn-sm w-xs"
          //   >
          //     {cellData.text}
          //     {cellData.icon && <i style={{ paddingLeft: '10px' }} className={cellData.icon} />}
          //   </a>,
          //   wrapper,
          // );
          // obj[step.columns[colIndex].text] = wrapper;
        } else if (step.columns[colIndex].type.checkOption('LockToggle'))
        {
          obj[step.columns[colIndex].text] = {
            type: 'toggle',
            rowIndex: rowIndex
          };

          // /** Render Lock Toggle button */
          // const wrapper = document.createElement('div');
          // ReactDOM.render(
          //   <LockSwitch
          //     record={records[rowIndex]}
          //     index={rowIndex}
          //     toggleTaskLockStatus={this.toggleTaskLockStatus}
          //   />,
          //   wrapper,
          // );
          // obj['lockToggle'] = wrapper;
        } else
        {
          /** Render normal text */
          obj[step.columns[colIndex].text] = cellData.text;
        }
      });
      return obj;
    });

    const isFloatingFilter = step.listOptions?.filter === 'Floating';
    const buttonWidth = step.listOptions?.buttonWidth ? Number(step.listOptions?.buttonWidth) : 160;

    return (
      <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
          pagination={true}
          onGridReady={this.onGridReady}
          onGridSizeChanged={this.onGridViewportResize}
          getRowStyle={this.getRowStyles}
        >
          {step.columns.map((col: StepTable.Column, index: number) =>
          {
            const isPID = col.text === 'Participant ID' || col.text === 'PID';
            const columnFormat = col.format.toLowerCase();
            const filterType =
              columnFormat == 'date'
                ? 'agDateColumnFilter'
                : columnFormat == 'number'
                  ? 'agTextColumnFilter'
                  : 'agTextColumnFilter';
            return (
              <AgGridColumn
                field={col.type.checkOption('LockToggle') ? 'lockToggle' : col.text}
                pinned={col.type.checkOption('LockToggle') ? 'right' : null}
                filter={col.is_action ? false : filterType}
                floatingFilter={isFloatingFilter}
                filterParams={{
                  buttons: ['reset', 'apply'],
                  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) =>
                  {
                    if (cellValue == null)
                    {
                      return 0;
                    }

                    if (cellValue == '')
                    {
                      return 1;
                    }

                    const cellDate = moment(cellValue);

                    if (cellDate.isBefore(moment(filterLocalDateAtMidnight)))
                    {
                      return -1;
                    } else if (cellDate.isAfter(moment(filterLocalDateAtMidnight)))
                    {
                      return 1;
                    }
                    return 0;
                  },
                }}
                sortable={true}
                resizable={col.is_action ? false : true}
                minWidth={isPID ? 150 : col.is_action ? buttonWidth : undefined}
                key={'col-' + col.text + index}
                cellStyle={{ 'text-align': col.align }}
                cellRenderer={this.cellRendererFunc}
                headerClass={'ag-header-align-' + col.align}
              ></AgGridColumn>
            );
          })}
        </AgGridReact>
      </div>
    );
  }
}

export default PageGrid;
