import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';

import { BookmarkModal, Footer, GearModal, Header, Sidebar, StudyHeader } from 'Common/components';
import { myProjectName } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
import QuickMenuRender from 'PageEngine/components/QuickMenuRender';

const cookies = new Cookies();

/**
 * MainLayout component.
 *
 * @remarks
 * Main Dashboard Layout for data monitors & participants
 *
 * @component MainLayout
 * @category Component
 */
const Layout = () =>
{
    const location = useLocation();
    const {
        leftSideBarType,
        setLeftSideBarType,
        showQuickMenu,
        showGearModal,
        showBookmarkModal,
        pageTitle,
        versionInfo,
        title_text
    } = useData();
    const { userType, isAuthenticated } = useAuth();

    useEffect(() =>
    {
        if (!isAuthenticated && location.pathname !== '/')
        {
            cookies.set('redirectUrl', location.pathname, { path: '/' });
        }
    }, []);

    useEffect(() =>
    {
        // Scroll Top to 0
        window.scrollTo(0, 0);

        if (leftSideBarType)
        {
            setLeftSideBarType(leftSideBarType);
        }
    }, [location.pathname, setLeftSideBarType, leftSideBarType]);

    const sanitizedPageTitle = pageTitle + ' | V' + versionInfo?.version + ' | ' + title_text

    const renderParticipantLayout = () =>
    {
        return (
            <div id="layout-wrapper">
                {showQuickMenu && myProjectName == 'demo' && <QuickMenuRender />}
                <StudyHeader></StudyHeader>
                <div className="participant-content">
                    <Outlet />
                </div>
                <Footer type={'participant'} />
            </div>
        );
    }

    const renderAdminLayout = () =>
    {
        return (
            <div id="layout-wrapper">
                {showQuickMenu && <QuickMenuRender />}
                {showGearModal && <GearModal />}
                {showBookmarkModal && <BookmarkModal />}
                <Header />
                <Sidebar />
                <div className="main-content">
                    <Outlet />
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{sanitizedPageTitle}</title>
                <meta name="title" content={sanitizedPageTitle} />
                <meta property="og:title" content={sanitizedPageTitle} />
            </Helmet>

            {userType === 'participant' || userType === 'magic' ? renderParticipantLayout() : renderAdminLayout()}
        </React.Fragment >
    );
};

export default Layout;
