import React from 'react';
import { Col,Container, Row } from 'reactstrap';

import { useData } from 'Common/context/DataContext';
import { sanitizeHtml } from 'Common/helpers';

const Footer = ({ type = 'admin' }) =>
{
  const { footer_text, footer_text_right, dashTitle } = useData();

  if (type === 'participant')
  {
    return (
      <React.Fragment>
        <footer className="participant-footer">
          <Container fluid={true}>
            <Row>
              <Col md={12} className="text-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(footer_text),
                  }}
                />
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row className="dark">
            <Col md={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(footer_text),
                }}
              />
            </Col>
            <Col md={6} className="text-right">
              {footer_text_right}
            </Col>
          </Row>
          <Row className="light">
            <Col md={6}>{dashTitle}</Col>
            <Col md={6} className="text-right"></Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
