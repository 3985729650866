import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Alert, Label, Spinner } from 'reactstrap';

import { LangServices } from '@lainaedge/platformshared';
import { AuthResult } from '@lainaedge/platformshared/src/types/AuthResult';
import { useAuth } from 'Common/context/AuthContext';
import { login } from 'Common/services';
import { ILoginFormFields } from 'Common/types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const cookies = new Cookies();
const langService = LangServices.instance();

/**
 * Authentication/pages/Login component.
 *
 * @remarks
 * Page where data monitor can log in
 *
 * @component Login
 * @category Page
 */
const Login = (): JSX.Element => {
  const { setUserType, setUser, error, setError, clearState, setIsAuthenticated } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  async function handleValidSubmit(values: ILoginFormFields) {
    clearState();
    SetIsSubmitting(true);
    const resp: AuthResult = await login(values);

    if (resp.isValidLogin) {
      const userObj = {
        username: resp.username!,
        firstName: resp.firstName,
        lastName: resp.lastName,
        email: resp.username!,
        userType: 'coordinator',
        token: resp.authToken!,
        role: resp.role,
        groups: resp.groups!,
      };
      cookies.set('authUser', userObj, { path: '/' });
      cookies.set('token', resp.authToken, { path: '/' });
      cookies.set('type', 'coordinator', { path: '/' });
      cookies.set('authOrigin', 'Trial', { path: '/' });
      setUser(userObj);
      setIsAuthenticated(true);
      setUserType('coordinator');
    } else {
      setError(
        resp.errorMessage
          ? langService.Translate(resp.errorMessage)
          : langService.Translate('Username or password is not correct'),
      );
    }

    // component is unmounted
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required(langService.Translate('Enter Username')),
          password: Yup.string().required(langService.Translate('Enter Password')),
        })}
        onSubmit={(values) => {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {error && error ? <Alert color="danger">{error}</Alert> : null}
            <div className="form-group">
              <Label for="username" className="form-label">
                {langService.Translate('Username')}
              </Label>
              <Field
                name="username"
                id="username"
                type="input"
                required
                placeholder={langService.Translate('Enter Username')}
                className={
                  'form-control' + (errors.username && touched.username ? ' is-invalid' : '')
                }
              />
              <ErrorMessage name="username" component="div" className="invalid-feedback" />
            </div>

            <div className="form-group">
              <Label for="password" className="form-label">
                {langService.Translate('Password')}
              </Label>
              <Field
                name="password"
                id="password"
                type="password"
                required
                placeholder={langService.Translate('Enter Password')}
                className={
                  'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                }
              />
              <ErrorMessage name="password" component="div" className="invalid-feedback" />
            </div>

            <div className="mt-3">
              <button
                className="btn btn-login btn-block waves-effect waves-light save-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {langService.Translate('Log In')}
                {isSubmitting && <Spinner className="spinner" />}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default Login;
