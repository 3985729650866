import React, { Component } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Modal } from 'reactstrap';

import { PageManager, StepWindow } from '@lainaedge/platformshared';
import { DataContext } from 'Common/context/DataContext';
import { RenderHelper } from 'PageEngine/components/RenderHelper';

enum TPageState {
  Loading,
  Finished,
  Error,
  RouteMissing,
}

/**
 * Props for [[`PageWindow`]] component
 */
export interface PageWindowProps {
  /** Step data */
  step: StepWindow;
  location: { pathname: string };
  navigate: NavigateFunction;
}

/**
 * Used for state for [[`PageTable`]] component
 */
export interface PageWindowState {
  isShowModal: boolean;
  allComponents: JSX.Element[];
  pageState: TPageState;
}

/**
 * PageWindow component
 *
 * @component PageWindow
 * @category PageElements
 */
export default class PageWindow extends Component<PageWindowProps, PageWindowState> {
  static contextType = DataContext;

  constructor(props: PageWindowProps) {
    super(props);
    this.state = {
      isShowModal: true,
      allComponents: [],
      pageState: TPageState.Loading,
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleToggleModal = this.handleToggleModal.bind(this);
  }

  /**
   * useEffect is called when the class is loaded.  we'll use that to pull down
   * the mobile config json data needed for page engine.
   */
  async componentDidMount() {
    await this.pageLoad();
  }

  async componentDidUpdate(prevProps: PageWindowProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.pageLoad();
    }
  }

  async pageLoad() {
    /** Load page by route from PageManager */
    const { navigate, step } = this.props;

    const routeName = step.option_1;

    const page = await PageManager.instance().findRoute(routeName);

    page.evInitializePage.subscribe(async (metaData: any) => {
      this.setState({ pageState: TPageState.Loading });
    });

    page.evFinishedPage.subscribe((metaData: any) => {
      /** On finishing page load, update the pageState state to hide the loading spinner */
      this.setState({ pageState: TPageState.Finished });
    });

    if (page && page.isValid()) {
      const rend_helper = new RenderHelper();

      rend_helper.navigate = navigate;
      rend_helper.location = this.props.location;
      rend_helper.context = this.context;
      await page.processPage(rend_helper);
      const list: JSX.Element[] = [];
      rend_helper.currentTarget.forEach((e) => {
        list.push(e as JSX.Element);
      });

      this.setState({
        allComponents: list,
      });
    } else {
      // Error condition, unable to find page
      this.setState({ pageState: TPageState.RouteMissing });
    }
  }

  handleCloseModal() {
    this.setState({
      isShowModal: false,
    });
  }

  handleToggleModal() {
    this.setState({
      isShowModal: !this.state.isShowModal,
    });
  }

  /**
   * Renders PageWindow class component.
   */
  public render(): JSX.Element {
    const { allComponents } = this.state;

    const step = this.props.step;

    console.log('window', step);
    return (
      <Modal
        size="lg"
        backdrop={true}
        isOpen={this.state.isShowModal}
        scrollable={true}
        toggle={this.handleToggleModal}
      >
        <div className="modal-header cursor-move">
          <h5 className="modal-title mt-0">{step.text}</h5>
          <button
            type="button"
            onClick={this.handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body page-window">{allComponents}</div>
      </Modal>
    );
  }
}
