import React from 'react';
import { InputGroup } from 'reactstrap';

import classNames from 'classnames';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';
/**
 * UnitInput component
 *
 * @component UnitInput
 * @category FormElements
 */
export default class UnitInput extends FormInput
{
  /**
   * Renders UnitInput class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    /** is the field disabled by conditionals ? */
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    let className = 'form-control';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';
    if (this.state.myAlign === 'right') className += ' text-right';

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    return (
      <>
        <div
          className={classNames(
            'd-inline-block unit-input-field mr-2 mb-1',
            this.isFieldDisabled() ? 'disabled' : '',
          )}
        >
          <InputGroup>
            <input
              className={className}
              type="text"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onChange={(e: any) =>
              {
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else
                {
                  this.handleChangeText(field, e);
                }
              }}
              onBlur={() =>
              {
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(
                    field,
                    this.props.formProps.edit_values[field.field].trim(),
                  );
                } else
                {
                  this.handleTrimText(field);
                }
              }}
            />
            {this.renderUnit()}
          </InputGroup>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
