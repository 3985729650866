import { Cookies } from 'react-cookie';

import { DataServices } from '@lainaedge/platformshared';
import axios from 'axios';
import { myProjectName,myServerEnv } from 'Common/constants';
import
{
  IForgetPWFormFields,
  ILoginFormFields,
  IParticipantForgetPWFormFields,
  IParticipantLoginFormFields,
  IParticipantResetPWFormFields,
  IResetPWFormFields,
} from 'Common/types';

const cookies = new Cookies();

const dataService = DataServices.instance();

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

/** Set up project environment for DataServices */
DataServices.setEnvironment(
  myServerEnv as Environment,
);
DataServices.setProjectName(myProjectName);
DataServices.setServerAddress('');

const apiBaseUrl = `https://${myServerEnv}.${myProjectName}.trial.lainaent.com/api/v1`;

function authToken()
{
  return cookies.get('token');
}

export function getAppVersion()
{
  return dataService.pingServer();
}

export function getEDCPortalConfig()
{
  return dataService.getEDCPortalConfig();
}

export function getDataDictionary()
{
  return dataService.getDataDictionary();
}

export function login(values: ILoginFormFields)
{
  return dataService.authUser(values.username.toLowerCase(), values.password, {
    version: '0.0.39',
  });
}

export function participantLogin(values: IParticipantLoginFormFields)
{
  return dataService.authParticipant(values.participantId.toLowerCase(), values.password, {
    version: '0.0.39',
  });
}

export function participantResetPasswordRequest(values: IParticipantForgetPWFormFields)
{
  return dataService.participantResetPasswordRequest(values.email);
}

export function participantResetPasswordWithCode(values: IParticipantResetPWFormFields)
{
  return dataService.participantResetPasswordWithCode(
    values.participantId,
    values.confirmationCode,
    values.newPassword,
  );
}

export function resetPasswordRequest(values: IForgetPWFormFields)
{
  return dataService.resetPasswordRequest(values.username);
}

export async function resetPasswordWithCode(values: IResetPWFormFields)
{
  return dataService.resetPasswordWithCode(
    values.username,
    values.verificationCode,
    values.password,
  );
}

export function authMagicCode(magicCode: string, study: string)
{
  return axios.post(`${apiBaseUrl}/auth/auth-magic-code`, {
    magicCode,
    study,
    metadata: {},
  });
}

export function getBookmark(username: string, pageUrl: string, studyCode: string)
{
  return axios.get(
    `${apiBaseUrl}/system/bookmark?username=${username}&pageUrl=${pageUrl}&studyCode=${studyCode}`,
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function saveBookmark(
  username: string,
  pageUrl: string,
  bookmarkTitle: string,
  studyCode: string,
)
{
  return axios.post(
    `${apiBaseUrl}/system/bookmark`,
    {
      username,
      pageUrl,
      bookmarkTitle,
      studyCode,
    },
    {
      headers: {
        'x-auth-token': authToken(),
      },
    },
  );
}

export function deleteBookmark(pageUrl: string, studyCode: string)
{
  return axios.delete(`${apiBaseUrl}/system/bookmark?pageUrl=${pageUrl}&studyCode=${studyCode}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function searchParticipant(query: string)
{
  return axios.get(`${apiBaseUrl}/participant/search?query=${query}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getMeddra(queryStr: string)
{
  return axios.get(`${apiBaseUrl}/drugs/meddra?query=${queryStr}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getMeddraDetails(meddraId: string)
{
  return axios.get(`${apiBaseUrl}/drugs/meddra?id=${meddraId}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function searchWhoDrug(whodrugId: string)
{
  return axios.get(`${apiBaseUrl}/drugs/whodrug?query=${whodrugId}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function getWhoDrugDetails(whodrugId: string)
{
  return axios.get(`${apiBaseUrl}/drugs/whodrug?id=${whodrugId}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}

export function sendConfirmationCode(phoneNumber: string)
{
  return dataService.sendConfirmationCode(phoneNumber);
}

export function verifyPhoneCode(phoneNumber: string, code: string)
{
  return dataService.verifyPhoneCode(phoneNumber, code);
}

export function generatePDF(pageUrl: string, fileName: string)
{
  return axios.get(`${apiBaseUrl}/cdn/generatePDF?pageUrl=${pageUrl}&fileName=${fileName}`, {
    headers: {
      'x-auth-token': authToken(),
    },
  });
}