import React, { Component } from 'react';
import { Button, Collapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LangServices, LogicEngine, StepForm, TaskManager } from '@lainaedge/platformshared';
import moment from 'moment';
import { RenderHelper } from 'PageEngine/components/RenderHelper';

const langService = LangServices.instance();

/**
 * Props for FoldableReview component.
 */
export interface FoldableReviewProps {
  /** Step data. */
  step: StepForm;
  formReviewed: boolean;
  /** Used for navigation. */
  renderer: RenderHelper;
}

/**
 * Used for state for [[`FoldableReview`]] component
 */
export interface FoldableReviewState {
  /** Collapse Open state for foldable section */
  isOpen: boolean;
  /** Checked state for the switch */
  isChecked: boolean;
  /** Reviewed_by state */
  reviewed_by: string;
  /** Reviewed_by state */
  reviewed_dt: any;
}

/**
 * FoldableReview component
 *
 * @component FoldableReview
 * @category FormElements
 */
export default class FoldableReview extends Component<FoldableReviewProps, FoldableReviewState> {
  /**
   * Constructor for FoldableReview class component.
   */
  constructor(props: FoldableReviewProps) {
    super(props);

    this.state = {
      isOpen: true,
      isChecked: this.props.formReviewed ? true : false,
      reviewed_by: '',
      reviewed_dt: '',
    };
  }

  async componentDidUpdate(prevProps: FoldableReviewProps) {
    if (this.props.formReviewed !== prevProps.formReviewed) {
      this.setState({ isChecked: this.props.formReviewed });
      this.getReviewerInformation();
    }
  }

  handleClick = async (targetRoute: string) => {
    this.props.renderer.navigate(targetRoute);
  };

  handleSwitchReview(): void {
    const step = this.props.step;
    const oldCheckedState = this.state.isChecked;
    this.setState({
      isChecked: !oldCheckedState,
      reviewed_by: oldCheckedState == false ? LogicEngine.currentUser : '',
      reviewed_dt: oldCheckedState == false ? new Date() : '',
    });
    step.setReviewStatus(!oldCheckedState);
    if (oldCheckedState == false) {
      if (step?.record) {
        step.record.data.reviewed_dt = new Date();
        step.record.data.reviewed_by = LogicEngine.currentUser;
      }
    }
  }

  renderFooterButtons(): JSX.Element {
    const step = this.props.step;

    const footerButtonFields = step.fields.filter((field: StepForm.FieldInfo) => {
      return field.type.checkOption('ReviewFooterButton');
    });

    if (!footerButtonFields.length) return <></>;

    const field = footerButtonFields[0];
    const target = step.logicRef ? step.logicRef.processTextReplacement(field.option1) : '';

    const targetRoute = target ? target : '';
    return (
      <Button
        className="ml-2"
        type="button"
        color="success"
        onClick={() => this.handleClick(targetRoute)}
      >
        {field.text}
      </Button>
    );
  }

  getReviewerInformation = async () => {
    const step = this.props.step;
    const taskDetail = await TaskManager.instance().getTaskDataById(step?.record?.data.task_id);
    this.setState({
      reviewed_by: taskDetail.record.data.reviewed_by,
      reviewed_dt: taskDetail.record.data.reviewed_dt,
    });
  };
  /**
   * Renders FoldableReview class component.
   */
  public render(): JSX.Element {
    return (
      <div className="foldable alert-warning">
        <div
          className="foldable-title row justify-content-between align-items-center"
          onClick={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
        >
          <div>
            {this.state.isChecked ? (
              <span>
                {langService.Translate(`This form was reviewed by ${this.state.reviewed_by} on`)}{' '}
                {moment(this.state.reviewed_dt).format('dddd, MMMM Do, YYYY HH:mm')}
              </span>
            ) : (
              <span>{langService.Translate('This form requires review')}</span>
            )}
          </div>
          <div>
            <div className="d-inline-block">
              <span className="action-title">
                {this.state.isOpen
                  ? langService.Translate('Close History')
                  : langService.Translate('Show History')}
              </span>
              <span className="action-icon mr-1">
                <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'} />
              </span>
            </div>
          </div>
        </div>
        <div className="foldable-content">
          <Collapse isOpen={this.state.isOpen}>
            <div className="pt-2 pb-2">
              {/* Render review history */}
              <p>
                {langService.Translate(
                  `Site instructions: Review the information. When complete, toggle the "I have reviewed the information" switch to indicate your review.`,
                )}
              </p>
              <div className="custom-control custom-switch d-flex align-items-center mr-1">
                <input
                  type="checkbox"
                  id="foldable-review-check"
                  className="custom-control-input"
                  checked={this.state.isChecked}
                  onChange={() => this.handleSwitchReview()}
                />
                <label className="custom-control-label" htmlFor="foldable-review-check">
                  {langService.Translate('I have reviewed the information')}
                </label>
                {this.renderFooterButtons()}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}
