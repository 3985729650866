import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Collapse, Progress, Row, Spinner } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataRecord,
  LangServices,
  LockManager,
  LogicEngine,
  QueryLog,
  StepForm,
  TaskManager,
} from '@lainaedge/platformshared';
import classNames from 'classnames';
import { MULTI_UNIT_INPUTS } from 'Common/constants';
import { DataContext } from 'Common/context/DataContext';
import { printEDCLog } from 'Common/helpers';
import moment from 'moment';
import { RenderHelper } from 'PageEngine/components/RenderHelper';
import toastr from 'toastr';

import AutoSuggestInput from '../../FormElements/AutoSuggestInput';
import Boolean from '../../FormElements/Boolean';
import DateInput from '../../FormElements/DateInput';
import DateTimeInput from '../../FormElements/DateTimeInput';
import Dropdown from '../../FormElements/Dropdown';
import FieldHistoryItem from '../../FormElements/FieldHistoryItem';
import FieldQueryItem from '../../FormElements/FieldQueryItem';
import FoldableReview from '../../FormElements/FoldableReview';
import LockHistoryItem from '../../FormElements/LockHistoryItem';
import Lookup from '../../FormElements/Lookup';
import MedicalHistoryName from '../../FormElements/MedicalHistoryName';
import MedicationDose from '../../FormElements/MedicationDose';
import MedicationSuggest from '../../FormElements/MedicationSuggest';
import MemoInput from '../../FormElements/MemoInput';
import MultiSelect from '../../FormElements/MultiSelect';
import MultiUnitInput from '../../FormElements/MultiUnitInput';
import NumberInput from '../../FormElements/NumberInput';
import SliderInput from '../../FormElements/SliderInput';
import Switch from '../../FormElements/Switch';
import TextAreaInput from '../../FormElements/TextAreaInput';
import TextInput from '../../FormElements/TextInput';
import TimeInput from '../../FormElements/TimeInput';
import { FormFieldGroup, PortalInputOptionsType } from '../../FormElements/types';
import UnitInput from '../../FormElements/UnitInput';
import YearInput from '../../FormElements/YearInput';
import FormEditModal from '../../FormModals/FormEditModal';
import QueryCreateModal from '../../FormModals/QueryCreateModal';
import QueryReplyModal from '../../FormModals/QueryReplyModal';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const langService = LangServices.instance();

/**
 * Used for props for [[`PageForm`]] component
 */
export interface PageFormProps {
  /** Step data */
  step: StepForm;
  /** Array of components or fieds */
  items: (JSX.Element | StepForm.FieldInfo | FormFieldGroup)[];
  /** Optional, form submittion handler. */
  handleSubmitForm?: () => void;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * Used for dynamic key value objects.
 */
export interface LooseObject {
  [key: string]: any;
}

/**
 * Used for state for [[`PageForm`]] component
 */
export interface PageFormState extends LooseObject {
  /** Used for displaying errors bellow the form field. */
  errors: LooseObject;
  /** Used to display validation warnings bellow the form field. */
  warnings: LooseObject;
  /** Used to display spinner and disable the button while saving. */
  is_saving: boolean;
  /** Represents record data. */
  data: LooseObject;
  /** Used to disable a field. */
  hide_fields: LooseObject;
  /** Optional, true if edit modal is opened for the field. */
  isShowEditModal?: boolean;
  /** Optional, array of edit items. */
  edit_items?: Array<StepForm.FieldInfo>;
  /** If empty sting displays errors in FormEditModal and QueryCreateModal components */
  // reason: string;
  /** Optional, boolean used to show and hide query modal. */
  isShowQueryModal?: boolean;
  /** Optional, reprisents query item */
  query_item?: StepForm.FieldInfo;
  /** Counts the number of updates. */
  counter: number;
  /** Represents currently active tab */
  activeTab: number;
  /** Represents a field and value pair. */
  edit_values: LooseObject;
  /** Optional, if true renders success SweetAlert component. */
  isShowQuerySuccessModal?: boolean;
  /** Optional, if true opens QueryReplyModal */
  isShowQueryReplyModal?: boolean;
  /** Optional, if true opens isShowWarningsModal */
  isShowWarningsModal?: boolean;
  isShowFormLockErrorModal?: boolean;
  /** Optional, single database record with Active record style functions. */
  query_record?: DataRecord;
  /** If empty string, displays error message in QueryReplyModal component. */
  message: string;
  /** Target for redirecting */
  targetRoute: string;
  /** If true displays Spinner. */
  loading: boolean;
  /** Optional, single page fields array */
  singlePageFields?: Array<string>;
  /** Single page step fields array */
  singlePageStepFields: Array<any[]>;
  /** Index of page fields for the single page format */
  singlePageFieldsIndex: number;
  /** Completion percentage for the single page format */
  singlePageProgress: number;
  /** Selected medication id */
  medication?: object;
  /** Selected medical history id */
  medicalHistoryId?: string;
  /** Form Reviewed status */
  formReviewed: boolean;
  /** Form Locked status */
  formLockStatus: false;
  /** Form Locked By Name */
  formLockBy: '';
  /** Form Locked By Date */
  formLockDt: '';
}

/**
 * PageForm component
 *
 * @component PageForm
 * @category PageElements
 */
export class PageForm extends Component<PageFormProps> {
  static contextType = DataContext;
  /**
   * Uses [[`PageFormState`]] interface as state object.
   */
  state: PageFormState = {
    errors: {},
    warnings: {},
    is_saving: false,
    data: {},
    hide_fields: {},
    isShowEditModal: false,
    isShowQueryModal: false,
    isShowQuerySuccessModal: false,
    isShowQueryReplyModal: false,
    isShowWarningsModal: false,
    isShowFormLockErrorModal: false,
    message: '',
    counter: 0,
    activeTab: 1,
    edit_items: [],
    edit_values: {},
    targetRoute: '',
    loading: false,
    singlePageFields: [],
    singlePageStepFields: [],
    singlePageFieldsIndex: 0,
    singlePageProgress: 0,
    formReviewed: false,
    formLockStatus: false,
    formLockBy: '',
    formLockDt: '',
  };

  constructor(props: PageFormProps) {
    super(props);

    const step = this.props.step;
    const data = step.record ? step.record.data : {};

    step.logicRef?.evFieldChanged.subscribe((options: any) => {
      printEDCLog('-=[ Event Field Changed: ' + options.field + ' = ' + options.enabled + ' ]=-');
      const hide_fields = this.state.hide_fields;
      hide_fields[options.field] = !options.enabled;
      if (!options.enabled) {
        this.props.step.setValueFromUser(options.field, '');
        this.setState({ [options.field]: '', hide_fields });
      } else {
        this.setState({ hide_fields });
      }
    });

    // Only on review page, call getReviewStatus()
    if (
      window.location.pathname.includes('_review') ||
      LogicEngine.currentGroups.includes('DataMonitor')
    ) {
      step.getReviewStatus().then((res: boolean) => {
        this.setState({ formReviewed: res });
      });
    }

    let index = -1;
    const stepFields: Array<any[]> = [];

    let keyNum = 1000;
    const list: any[] = [];

    this.props.items.forEach((item: any) => {
      if (item.type == 'Group' || item.type == 'ButtonMenu') {
        if (item?.children.length) {
          list.push({
            key: item.key,
            item:
              item.type == 'ButtonMenu' ? (
                <div className="button-menu">
                  <table className="button-list-table table-nowrap table-centered table-borderless table">
                    {this.renderGroupItemChildren(item?.children, keyNum++)}
                  </table>
                </div>
              ) : (
                <div className="col-md-12 form-field-group">
                  {this.renderGroupItemChildren(item?.children, keyNum++)}
                </div>
              ),
          });
        }
      } else if (item.hasOwnProperty('key')) {
        /** This is already a JSX.Element object from the main RenderHelper */
        list.push({
          key: (item as JSX.Element).key,
          item: item as JSX.Element,
          field: (item as JSX.Element).key,
        });
      } else {
        /** This is a Form Element, render it within render() */
        list.push({
          key: 'item_' + keyNum++,
          item: item,
          field: (item as StepForm.FieldInfo).field,
          isNewPage: item?.option2 === 'NewPage',
          isFormElement: true,
        });
      }
    });

    for (let i = 0; i < list.length; i++) {
      if (list[i]?.isNewPage || index === -1) {
        index++;
        stepFields.push([]);
      }
      stepFields[index].push(list[i]);
    }

    this.state = {
      errors: {},
      warnings: {},
      is_saving: false,
      data: data,
      hide_fields: {},
      isShowEditModal: false,
      isShowQueryModal: false,
      isShowQuerySuccessModal: false,
      isShowQueryReplyModal: false,
      isShowWarningsModal: false,
      isShowFormLockErrorModal: false,
      message: '',
      counter: 0,
      activeTab: 1,
      edit_items: [],
      edit_values: {},
      targetRoute: '',
      loading: false,
      singlePageFields: [step.fields[0].field],
      singlePageStepFields: stepFields,
      singlePageFieldsIndex: 0,
      singlePageProgress: 0,
      formReviewed: false,
      formLockStatus: false,
      formLockBy: '',
      formLockDt: '',
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleQueryModal = this.toggleQueryModal.bind(this);
    this.toggleQuerySuccessModal = this.toggleQuerySuccessModal.bind(this);
    this.toggleFormLockErrorModal = this.toggleFormLockErrorModal.bind(this);
    this.updateMedication = this.updateMedication.bind(this);
    this.toggleMedicationLoading = this.toggleMedicationLoading.bind(this);
    this.getLockInformation = this.getLockInformation.bind(this);
  }

  renderGroupItemChildren(children: Array<any>, keyNum: number) {
    return (
      <>
        {children.map((item: StepForm.FieldInfo | JSX.Element) => {
          /** This is already a JSX.Element object from the main RenderHelper */
          if (item.hasOwnProperty('key')) {
            return <React.Fragment key={'group_item_' + keyNum++}>{item}</React.Fragment>;
          } else {
            const fieldName = (item as StepForm.FieldInfo).field;
            const queryRecords = this.props.step.formQuery.filter((record: DataRecord) => {
              return record.data.table_field === fieldName;
            });

            const historyRecords = this.props.step.formHistory.filter((record: DataRecord) => {
              return record.data.table_field === fieldName;
            });

            /** This is a Form Element, render it within render() */
            return (
              <div
                className={
                  queryRecords.length > 0 || historyRecords.length > 0
                    ? 'col-md-12'
                    : 'col-lg-3 col-md-4 col-sm-6'
                }
                key={'group_item_' + keyNum++}
                data-field={fieldName}
              >
                {this.renderFormField(this.props.step, item as StepForm.FieldInfo, false)}
              </div>
            );
          }
        })}
      </>
    );
  }

  componentDidMount() {
    const step = this.props.step;
    this.getLockInformation();
    if (step.option_1 === 'SinglePageFormat') {
      // Use Option2 Field as Footer for Single Page Format
      this.context?.setFooterText(step.fields[0].option1 === 'NoFooter' ? '' : step.option_2);
    }
  }

  async componentDidUpdate(prevProps: PageFormProps) {
    if (this.props.step.key_id !== prevProps.step.key_id) {
      const step = this.props.step;
      this.getLockInformation();
      if (step.option_1 !== 'SinglePageFormat') {
        let footer_text = new Date().getFullYear() + ' © Laina';
        const config = this.context?.config;
        if (!config || !config.table || !config.table.WebsiteConfig) {
          // Avoid failure due to missing config data
          return;
        }
        const WebsiteConfig = config.tables.WebsiteConfig;
        const settings = WebsiteConfig[0].data;
        // build Site Config
        if (settings) {
          const footer: any = settings.find((item: any) => item.setting === 'Footer_text');
          if (footer) footer_text = footer.value;
        }
        this.context?.setFooterText(footer_text);
      }
    }
  }

  /** Open/Close Edit Modal and reset values */
  toggleEditModal = () => {
    const step = this.props.step;
    const data = step.record ? step.record.data : {};

    this.setState({
      isShowEditModal: !this.state.isShowEditModal,
      data,
      edit_items: [],
      edit_values: {},
      errors: [],
      warnings: {},
      activeTab: 1,
      counter: this.state.counter + 1,
      is_saving: false,
    });
  };

  /** Open/Close Query modal and reset values */
  toggleQueryModal = () => {
    this.setState({
      isShowQueryModal: !this.state.isShowQueryModal,
      errors: [],
      warnings: {},
      is_saving: false,
    });
  };

  /** Open/Close Query Success modal */
  toggleQuerySuccessModal = () => {
    this.setState({
      isShowQuerySuccessModal: !this.state.isShowQuerySuccessModal,
    });
  };

  /** Open/Close Form Lock Error modal */
  toggleFormLockErrorModal = () => {
    this.setState({
      isShowFormLockErrorModal: !this.state.isShowFormLockErrorModal,
    });
  };

  /** Open Edit Modal for the field */
  handleEditField = (field: StepForm.FieldInfo) => {
    this.setState({
      edit_items: [field],
      edit_values: { [field.field]: '' },
      isShowEditModal: !this.state.isShowEditModal,
    });
  };

  /** Open Query Modal for the field */
  handleQueryField = (field: StepForm.FieldInfo) => {
    this.setState({
      query_item: field,
      isShowQueryModal: !this.state.isShowQueryModal,
    });
  };

  /**
   * Sets the query_item.field to 'FORM' and toggles the value of isShowQueryModal.
   */
  handleGeneralQuery = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      query_item: {
        field: 'FORM',
      },
      isShowQueryModal: !this.state.isShowQueryModal,
    });
  };

  debugMessage(message: string, obj: any) {
    console.log('Form debugMessage', message, obj);
  }

  /**
   * Changes edit values of the field.
   *
   * @param field - Field to change the edit values of.
   * @param value - New value
   * @returns Void
   */
  handleChangeEditValues = (field: StepForm.FieldInfo, value: string) => {
    const v = this.state.edit_values;
    v[field.field] = value;
    this.setState({ edit_values: v });
  };

  /** Scroll to the field when validation fails */
  scrollToField = (field: string) => {
    let el: any = document.querySelector(`div[data-field="${field}"]`);
    if (!el) return;

    let offsetY = 0;
    while (el) {
      offsetY += el.offsetTop;
      el = el.offsetParent;
    }

    const pageContentDiv = document.getElementsByClassName('page-content')[0];
    pageContentDiv.scrollTo({
      top: offsetY - 84,
      left: 0,
      behavior: 'smooth',
    });
  };

  /** Go to the tab in the edit modal */
  toggleTab = (tab: number) => {
    if (this.state.activeTab !== tab && this.state.edit_items) {
      if (tab >= 1 && tab <= this.state.edit_items.length + 1) {
        this.setState({ activeTab: tab });
      }
    }
  };

  /**
   * Used to update task lock status.
   *
   * @param status - Boolean. If true locks the task, otherwise unlocks it.
   * @returns Promise (void)
   */
  updateTaskLockStatus = async (status: boolean) => {
    const record: DataRecord | undefined = this.props.step.record;
    if (record && record.data && record.tableName) {
      const logicRef = new LogicEngine();
      LogicEngine.currentGroups = ['DataMonitor'];

      const l: LockManager = new LockManager(logicRef);

      if (status) {
        const result = await l.lockRecord(
          record.tableName,
          record.data.id,
          langService.Translate('Locked with toggle button'),
          false,
        );

        if (
          result === LockManager.LockResult.Locked ||
          result === LockManager.LockResult.AlreadyLocked
        ) {
          toastr.success(langService.Translate('Task Lock Success'), 'Success');
        } else {
          toastr.error(langService.Translate('Task Lock Failure'), 'Failure');
        }
      } else {
        const result = await l.unlockRecord(
          record.tableName,
          record.data.id,
          langService.Translate('Unlocked by Edit Fields'),
          false,
        );

        if (
          result === LockManager.LockResult.Unlocked ||
          result === LockManager.LockResult.AlreadyUnlocked
        ) {
          toastr.success(langService.Translate('Task Unlock Success'), 'Success');
        } else {
          toastr.error(langService.Translate('Task Unlock Failure'), 'Failure');
        }
      }
    }
  };

  /**
   * Save Edit modal form in edit mode
   * @param reason: A string value for reason
   * @returns {Promise<void>}
   */
  handleEditSave = async (reason: string): Promise<void> => {
    if (reason === '') {
      const errors: LooseObject = {};

      errors['reason'] = langService.Translate('This field is required and may not be left empty.');
      this.setState({ errors: errors, activeTab: 1 });
      return;
    }
    if (this.state.edit_items && this.state.edit_items.length) {
      const step = this.props.step;

      this.setState({ is_saving: true, errors: [] });

      const values: LooseObject = {};
      this.state.edit_items.map((item) => {
        values[item.field] = this.state.edit_values[item.field];
        return item;
      });

      const result = await step.saveEditFields(values, reason);

      const errors: LooseObject = {};

      if (result.length === 0) {
        const openQueries = step.formQuery.filter((record: DataRecord) => {
          return (
            this.state.edit_items &&
            record.data.table_field === this.state.edit_items[0].field &&
            record.data.query_status === QueryLog.QueryStatus.Open
          );
        });

        await Promise.all(
          openQueries.map(async (query: DataRecord) => {
            await step.saveQueryReply(
              query.data.id,
              langService.Translate('Value updated using Edit'),
            );
          }),
        );

        /** Unlock task */
        await this.updateTaskLockStatus(false);

        this.toggleEditModal();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return;
      }

      let items = [...this.state.edit_items];
      let error_index = 0;
      result.forEach((error_item: any) => {
        let exist = false;

        /** Check if the error field exists in the edit form steps */
        this.state.edit_items?.map((item, idx) => {
          if (item.field === error_item.field_name) {
            exist = true;
            error_index = idx;
          }
        });

        items.map((item: StepForm.FieldInfo) => {
          if (item.field === error_item.field_name) {
            exist = true;
          }
          return item;
        });

        if (!exist) {
          /** If the error field doesn't exist in the edit form steps, add them  */
          let error_field: StepForm.FieldInfo | null = null;
          this.props.items.map((p_item) => {
            if ((p_item as StepForm.FieldInfo).field === error_item.field_name) {
              error_field = p_item as StepForm.FieldInfo;
            }
            return p_item;
          });

          if (error_field) {
            items = [...items, error_field];
            error_index = items.length - 1;
          }
        }

        if (errors[error_item['field_name']]) {
          errors[error_item['field_name']] += '<br/>' + error_item['error_message'];
        } else {
          errors[error_item['field_name']] = error_item['error_message'];
        }
      });

      this.setState({
        edit_items: items,
        errors: errors,
        is_saving: false,
        activeTab: error_index + 2,
      });
    }
  };

  /** Save Query modal form in query mode */
  handleQuerySave = async (reason: string) => {
    if (reason === '') {
      const errors: LooseObject = {};

      errors['reason'] = langService.Translate('This field is required and may not be left empty.');
      this.setState({ errors: errors });
      return;
    }
    if (this.state.query_item) {
      const step = this.props.step;

      this.setState({ is_saving: true, errors: [] });

      const currentLockStatus = this.state.formLockStatus;

      if (currentLockStatus) {
        /** Unlock task */
        await this.updateTaskLockStatus(false);
      }

      await step.saveQueryField(this.state.query_item.field, reason);

      this.toggleQueryModal();
      this.toggleQuerySuccessModal();
    }
  };

  /** Save form in create mode */
  handleSave = async (targetRoute: string): Promise<boolean> => {
    this.setState({ is_saving: true, errors: [] });
    const result = await this.props.step.checkPage(false);

    if (result.length === 0) {
      await this.props.renderer.submitForm(true);
      this.props.renderer.navigate(targetRoute);
    } else {
      this.scrollToField(result[0]['field_name']);
    }

    const errors: LooseObject = {};
    const warningArray: string[] = [];
    const errorArray: string[] = [];
    const warningsObj: LooseObject = [];
    result.forEach((item) => {
      if (item['field_status'] === 'warning') {
        if (warningsObj[item['field_name']]) {
          warningsObj[item['field_name']] += '<br/>' + item['error_message'];
        } else {
          warningsObj[item['field_name']] = item['error_message'];
        }
        warningArray.push(item['error_message']);
      } else {
        if (errors[item['field_name']]) {
          errors[item['field_name']] += '<br/>' + item['error_message'];
        } else {
          errors[item['field_name']] = item['error_message'];
        }
        errorArray.push(item['error_message']);
      }
    });

    if (warningArray.length) {
      this.setState({
        targetRoute,
        errors: errors,
        warnings: warningsObj,
        is_saving: false,
      });
      this.toggleWarningsModal();
    } else {
      this.setState({ errors: errors, warnings: warningsObj, is_saving: false });
    }
    return errorArray.length === 0;
  };

  /** Check form validation for single page format */
  handleCheckValidation = async (): Promise<boolean> => {
    this.setState({ is_saving: true, errors: [] });

    const result = await this.props.step.checkPage(false);

    if (result.length > 0) {
      this.scrollToField(result[0]['field_name']);
    }

    const errors: LooseObject = {};
    const warningArray: string[] = [];
    const errorArray: string[] = [];
    const warningsObj: LooseObject = {};
    result.forEach((item: any) => {
      if (item['field_status'] === 'warning') {
        if (warningsObj[item['field_name']]) {
          warningsObj[item['field_name']] += '<br/>' + item['error_message'];
        } else {
          warningsObj[item['field_name']] = item['error_message'];
        }
        warningArray.push(item['error_message']);
      } else {
        if (errors[item['field_name']]) {
          errors[item['field_name']] += '<br/>' + item['error_message'];
        } else {
          errors[item['field_name']] = item['error_message'];
        }
        errorArray.push(item['error_message']);
      }
    });

    this.setState({ errors: errors, warnings: warningsObj, is_saving: false });
    return errorArray.length === 0 && warningArray.length === 0;
  };

  /** Render Edit Button next to form field */
  renderEditButton(field: StepForm.FieldInfo): JSX.Element {
    return (
      <div className={'col-md-4'}>
        <Button
          className="save-btn"
          type="button"
          color="primary"
          disabled={this.state.is_saving || this.state.hide_fields[field.field]}
          onClick={() => this.handleEditField(field)}
        >
          {langService.Translate('Edit')}
        </Button>
      </div>
    );
  }

  /** Render Query Button next to form field */
  renderQueryButton(field: StepForm.FieldInfo): JSX.Element {
    return (
      <div className={'col-md-4'}>
        <Button
          className="save-btn"
          type="button"
          color="primary"
          disabled={this.state.is_saving || this.state.hide_fields[field.field]}
          onClick={() => this.handleQueryField(field)}
        >
          {langService.Translate('Query')}
        </Button>
      </div>
    );
  }

  /** Render Field Histories next to form field */
  renderFieldHistory(fieldName: string): JSX.Element {
    const step = this.props.step;

    const records = step.formHistory.filter((record: DataRecord) => {
      return record.data.table_field === fieldName;
    });

    const emptyMessage =
      fieldName === 'FORM' ? (
        ''
      ) : (
        <div className="field-history-table">
          <i> {langService.Translate('No change history found for this field')} </i>
        </div>
      );
    return (
      <div className={'col-md-12'}>
        {records.length ? (
          <FieldHistoryItem
            fieldName={fieldName}
            records={records}
            lockHistory={step.lockHistory}
          ></FieldHistoryItem>
        ) : (
          emptyMessage
        )}
      </div>
    );
  }

  /** Render Query Histories next to form field */
  renderFieldQueries(field_name: string): JSX.Element {
    const step = this.props.step;

    const records = step.formQuery.filter((record: DataRecord) => {
      return record.data.table_field === field_name;
    });

    return records.length ? (
      <div className={'col-md-12'}>
        <FieldQueryItem
          records={records}
          lockHistory={step.lockHistory}
          is_edit_mode={step.is_edit_mode}
          is_query_mode={step.is_query_mode}
          field_name={field_name}
          showReplyModal={this.showQueryReplyModal}
          acceptQuery={this.acceptQuery}
          closeQuery={this.closeQuery}
        ></FieldQueryItem>
      </div>
    ) : (
      <></>
    );
  }

  /** Render Lock History */
  renderLockHistory(): JSX.Element {
    const step = this.props.step;

    return (
      <div className="row">
        <div className={'col-md-12'}>
          {step.lockHistory.length ? (
            <LockHistoryItem records={step.lockHistory}></LockHistoryItem>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }

  /** Open Reply Modal for the field */
  showQueryReplyModal = (record: DataRecord) => {
    this.setState({
      query_record: record,
      isShowQueryReplyModal: !this.state.isShowQueryReplyModal,
    });
  };

  /** Open/Close Reply modal and reset values */
  toggleQueryReplyModal = () => {
    this.setState({
      isShowQueryReplyModal: !this.state.isShowQueryReplyModal,
      is_saving: false,
    });
  };

  /** Open/Close Reply modal and reset values */
  toggleWarningsModal = () => {
    this.setState({
      isShowWarningsModal: !this.state.isShowWarningsModal,
    });
  };

  /** Accept Query Reply */
  acceptQuery = async (record: DataRecord) => {
    if (record && record.id) {
      const step = this.props.step;

      this.setState({ is_saving: true, errors: [] });

      await step.closeQuery(record.id, QueryLog.QueryStatus.Resolved, '');

      this.setState({ is_saving: false, errors: [] });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  /** Close Query: update to Deleted status */
  closeQuery = async (record: DataRecord) => {
    if (record && record.id) {
      const step = this.props.step;

      this.setState({ is_saving: true, errors: [] });

      await step.closeQuery(record.id, QueryLog.QueryStatus.Deleted, '');

      this.setState({ is_saving: false, errors: [] });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  /** Save Query Reply in edit & query mode */
  handleQueryReplySave = async (message: string) => {
    if (this.state.query_record && this.state.query_record.id) {
      const step = this.props.step;

      this.setState({ is_saving: true, errors: [] });

      if (this.props.step.is_edit_mode) {
        if (message === '') {
          const errors: LooseObject = {};

          errors['message'] = langService.Translate(
            'This field is required and may not be left empty.',
          );
          this.setState({ is_saving: false, errors: errors });
          return;
        }
        await step.saveQueryReply(this.state.query_record.id, message);
      }

      if (this.props.step.is_query_mode) {
        await step.closeQuery(
          this.state.query_record.id,
          message === '' ? QueryLog.QueryStatus.Resolved : QueryLog.QueryStatus.NotResolved,
          message,
        );
        this.setState({ is_saving: false, errors: [] });
      }

      this.toggleQueryReplyModal();

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  /** Toggle Form Lock Switch */
  handleFormLockToggle = async (e: React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const openQueries: DataRecord[] = this.props.step.formQuery.filter((record: DataRecord) => {
      return record.data.query_status === QueryLog.QueryStatus.Open;
    });

    const currentLockStatus = this.state.formLockStatus;

    if (openQueries.length && !currentLockStatus) {
      this.toggleFormLockErrorModal();
      return;
    }

    this.setState({
      loading: true,
    });

    console.log(this.state.formLockStatus ? !this.state.formLockStatus : true);

    /** Lock Task */
    await this.updateTaskLockStatus(this.state.formLockStatus ? !this.state.formLockStatus : true);

    this.setState({
      counter: this.state.counter + 1,
      loading: false,
    });

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  /** Render Validaion error below the form field */
  renderValidationError(field_name: string): JSX.Element {
    return (
      <div className="col-md-12">
        {this.state.errors[field_name] && (
          <span className="validation-error">
            {htmlToReactParser.parse(this.state.errors[field_name])}
          </span>
        )}
        {this.state.warnings[field_name] && (
          <span className="validation-warning">
            {htmlToReactParser.parse(this.state.warnings[field_name])}
          </span>
        )}
      </div>
    );
  }

  /** Update local state medication id */
  updateMedication(med: object): void {
    this.setState({ medication: med });
  }

  /** Update local state medicationLoading */
  toggleMedicationLoading(newState: boolean): void {
    this.setState({ medicationLoading: newState });
  }

  /** Render the form field */
  renderInputField(
    field: StepForm.FieldInfo,
    is_on_modal: boolean,
    is_disabled?: boolean,
  ): JSX.Element {
    const step = this.props.step;

    // const data = step.record ? step.record.data : {};

    const type = step.tableDef?.getFieldType(field.field);

    const formProps: PortalInputOptionsType = {
      step,
      is_on_modal,
      is_disabled,
      field,
      errors: this.state.errors,
      warnings: this.state.warnings,
      handleChangeEditValues: this.handleChangeEditValues,
      edit_values: this.state.edit_values,
      hide_fields: this.state.hide_fields,
      counter: this.state.counter,
    };
    if (type === 'Text') {
      if (field.option1 === 'Memo') {
        return <MemoInput formProps={formProps} />;
      }
      if (field.option1 === 'Date') {
        return <DateInput formProps={formProps} />;
      }
      if (field.type.checkOption('html') === true) {
        return <></>;
      }
      if (field.type.checkOption('HtmlImage') === true) {
        return <img alt={'Form Help'} src={field.text} width={field.option1} />;
      }
      if (field.type.checkOption('PDF') === true || field.type.checkOption('PDFSimple') === true) {
        return (
          <iframe
            title={field.text}
            className="pdfViewer"
            style={{ width: '600px' }}
            src={field.text + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH,100'}
          ></iframe>
        );
      }
      if (field.type.checkOption('EICButton') === true) {
        const targetAction = step.logicRef?.processTextReplacement(field.option1);
        return (
          <Button
            type="button"
            onClick={() => this.props.renderer.navigate(targetAction)}
            className={'btn btn-success w-xs waves-effect waves-light'}
          >
            {field.text}
          </Button>
        );
      }
      return <TextInput formProps={formProps} />;
    } else if (type === 'Number' || type === 'Decimal') {
      return <NumberInput formProps={formProps} />;
    } else if (type === 'Date') {
      return <DateInput formProps={formProps} />;
    } else if (type === 'Time') {
      return <TimeInput formProps={formProps} />;
    } else if (type === 'DateTime') {
      return <DateTimeInput formProps={formProps} />;
    } else if (type === 'Lookup' && step.tableDef && step.dataDictionary) {
      if (field.option1 === 'Autocomplete') {
        return <AutoSuggestInput formProps={formProps} />;
      }

      return <Lookup formProps={formProps} />; // Multiple Radio
    } else if (type === 'MultiSelect') {
      return <MultiSelect formProps={formProps} />; // Multiple Checkbox
    } else if (type === 'Boolean') {
      return <Boolean formProps={formProps} />; // Yes/No Radio
    } else if (type === 'Switch') {
      return <Switch formProps={formProps} />; // Yes/No Checkbox
    } else if (type === 'Slider') {
      return <SliderInput formProps={formProps} />;
    } else if (type && MULTI_UNIT_INPUTS.includes(type)) {
      const defaultUnits: any = {
        MeasureWeight: 'kg',
        MeasurePhysical: 'cm',
        MeasureLiquid: 'ml',
        PhysicalTemp: 'C',
      };
      const defaultUnit = defaultUnits[type as string];
      return <MultiUnitInput formProps={formProps} defaultUnit={defaultUnit} />;
    } else if (type === 'Year') {
      return <YearInput formProps={formProps} />;
    } else if (type === 'MedicationSuggest') {
      return (
        <MedicationSuggest
          formProps={formProps}
          updateMedication={this.updateMedication}
          toggleMedicationLoading={this.toggleMedicationLoading}
        />
      );
    } else if (type === 'MedicationDose') {
      return (
        <MedicationDose
          formProps={formProps}
          medication={this.state.medication}
          medicationLoading={this.state.medicationLoading}
        />
      );
    } else if (type === 'MedicalHistoryName') {
      return <MedicalHistoryName formProps={formProps} />;
    } else if (type === 'Dropdown') {
      return <Dropdown formProps={formProps} />;
    } else if (type === 'ShortInput') {
      return <TextInput formProps={formProps} />;
    } else if (type === 'Memo') {
      return <TextAreaInput formProps={formProps} />;
    } else {
      return <UnitInput formProps={formProps} />;
    }
  }

  /** Return element width for the form field */
  getRenderWidth = (field: StepForm.FieldInfo, is_on_modal: boolean) => {
    const step = this.props.step;
    const type = step.tableDef?.getFieldType(field.field);

    if (
      type === 'Lookup' ||
      type === 'Boolean' ||
      type === 'Switch' ||
      type === 'Number' ||
      type === 'Decimal' ||
      type === 'Text' ||
      type === 'Date' ||
      type === 'DateTime' ||
      type === 'Time' ||
      type === 'Year' ||
      type === 'Dropdown' ||
      type === 'ShortInput'
    ) {
      if (step.is_query_mode) return 'col-md-4';
      return step.is_edit_mode ? (is_on_modal ? 'col-md-8' : 'col-md-4') : 'col-md-12';
    } else if (type === 'MeasureWeight' || type === 'MeasurePhysical' || type === 'MeasureLiquid') {
      return 'col-md-12';
    } else if (type === 'Slider') {
      return is_on_modal ? 'col-md-12' : 'col-md-6';
      // (type == 'DateTime' || type == 'Date' || type == 'Time' || type == 'Year') {
      // return is_on_modal ? 'col-md-8' : 'col-md-4';
    } else {
      // Unit, and others
      return 'col-md-12';
    }
  };

  getLockInformation = async () => {
    const step = this.props.step;
    const taskDetail = await TaskManager.instance().getTaskDataById(step?.record?.data.task_id);
    this.setState({
      formLockStatus: taskDetail.record.data.lock_status,
      formLockBy: taskDetail.record.data.lock_by,
      formLockDt: taskDetail.record.data.lock_dt,
    });
  };

  /** Render Form Field */
  renderFormField(
    step: StepForm,
    field: StepForm.FieldInfo,
    is_on_modal: boolean,
    is_disabled?: boolean,
  ): JSX.Element {
    const labelClassname =
      is_disabled || this.state.hide_fields[field.field]
        ? 'col-md-12 col-form-label disabled'
        : 'col-md-12 col-form-label';

    const type = step.tableDef?.getFieldType(field.field);

    if (type === 'FoldableReview') {
      return <></>;
    }
    field.text = field.text.replaceAll('<BoldBlue>', '<b style="color: blue">');
    field.text = field.text.replaceAll('</BoldBlue>', '</b>');

    field.text = field.text.replaceAll('<BoldBlueItalic>', '<i><b style="color: blue">');
    field.text = field.text.replaceAll('</BoldBlueItalic>', '</b></i>');

    field.text = field.text.replaceAll('<BoldItalic>', '<i><b>');
    field.text = field.text.replaceAll('</BoldItalic>', '</b></i>');

    if (field.type.checkOption('Button')) {
      if (step.is_edit_mode) return <></>;
      const target = step.logicRef ? step.logicRef.processTextReplacement(field.option1) : '';

      const targetRoute = target ? target : '';
      return (
        <div className="col-md-12 mt-2 mb-2">
          <Button
            className="save-btn"
            type="button"
            color="primary"
            disabled={this.state.is_saving}
            onClick={() => this.handleSave(targetRoute)}
          >
            {field.text}
            {this.state.is_saving && <Spinner className="spinner" />}
          </Button>
        </div>
      );
    } else if (field.type.checkOption('ReviewFooterButton')) {
      return <></>;
    } else {
      return (
        <>
          {type !== 'Switch' &&
            field.type.checkOption('HtmlImage') !== true &&
            field.type.checkOption('PDF') !== true &&
            field.type.checkOption('PDFSimple') !== true &&
            field.type.checkOption('EICBUtton') !== true && (
              <label className={labelClassname}>{htmlToReactParser.parse(field.text)}</label>
            )}
          <div className={this.getRenderWidth(field, is_on_modal)}>
            {this.renderInputField(
              field,
              is_on_modal,
              is_disabled || this.state.hide_fields[field.field],
            )}
          </div>
          {field.type.checkOption('html') !== true &&
          field.type.checkOption('PDF') !== true &&
          field.type.checkOption('PDFSimple') !== true &&
          field.type.checkOption('HtmlImage') !== true ? (
            <>
              {/** Not render Query and Edit buttons for disabled fields */}
              {field.enabled === false
                ? ''
                : step.is_edit_mode && !is_on_modal && this.renderEditButton(field)}
              {step.is_query_mode && !is_on_modal && this.renderQueryButton(field)}
              {(step.is_query_mode || step.is_edit_mode) &&
                !is_on_modal &&
                this.renderFieldHistory(field.field)}
              {(step.is_query_mode || step.is_edit_mode) &&
                !is_on_modal &&
                this.renderFieldQueries(field.field)}
            </>
          ) : (
            ''
          )}
        </>
      );
    }
  }

  /** Render foldable view for _review : not for RB1 */
  renderFoldableReview(): JSX.Element {
    const step = this.props.step;
    // LogicEngine.currentGroups.includes('DataMonitor')
    if (
      !window.location.pathname.includes('_review') &&
      !LogicEngine.currentGroups.includes('DataMonitor')
    ) {
      return <></>;
    }

    return (
      <FoldableReview
        step={step}
        formReviewed={this.state.formReviewed}
        renderer={this.props.renderer}
      />
    );
  }

  /** Load previous page fields for the single page format */
  handleGoBackSinglePage(): void {
    const step = this.props.step;
    let newIndex = this.state.singlePageFieldsIndex - 1;

    let foundStep = false;
    while (!foundStep && newIndex >= 0) {
      let allDisabled = true;
      this.state.singlePageStepFields[newIndex].map((field) => {
        if (!this.state.hide_fields[field.field]) {
          allDisabled = false;
        }
      });
      if (!allDisabled) {
        foundStep = true;
        break;
      }
      if (!foundStep) {
        newIndex--;
      }
    }

    if (newIndex >= 0) {
      this.setState({
        singlePageFieldsIndex: newIndex,
        singlePageProgress: Math.round((newIndex / this.state.singlePageStepFields.length) * 100),
      });

      // Hide Footer text for NoFooter
      this.context?.setFooterText(
        this.state.singlePageStepFields[newIndex][0].option1 === 'NoFooter' ? '' : step.option_2,
      );
    }
  }

  /** Load next page fields for the single page format */
  async handleGoNextSinglePage(): Promise<void> {
    const step = this.props.step;
    const success: boolean = await this.handleCheckValidation();

    const fields: StepForm.FieldInfo[] =
      this.state.singlePageStepFields[this.state.singlePageFieldsIndex];

    let errorLength = 0;
    let warningLength = 0;
    const filteredWarnings: any = {};
    let firstErrorField: any = {};

    fields.forEach((field: StepForm.FieldInfo) => {
      if (field) {
        if (this.state.errors[field.field]) {
          errorLength++;
          firstErrorField = field.field;
        }
        if (this.state.warnings[field.field]) {
          filteredWarnings[field.field] = this.state.warnings[field.field];
          warningLength++;
        }
      }
    });

    if (errorLength === 0 && warningLength > 0) {
      this.setState({
        warnings: filteredWarnings,
      });
      this.toggleWarningsModal();
    }

    if (success || (errorLength === 0 && warningLength === 0)) {
      const fields = this.props.step.fields;
      let newIndex = this.state.singlePageFieldsIndex + 1;

      let foundStep = false;
      while (!foundStep && newIndex < fields.length) {
        let allDisabled = true;
        this.state.singlePageStepFields[newIndex].map((field) => {
          if (!this.state.hide_fields[field.field]) {
            allDisabled = false;
          }
        });
        if (!allDisabled) {
          foundStep = true;
          break;
        }
        if (!foundStep) {
          newIndex++;
        }
      }

      if (fields.length > newIndex) {
        this.setState({
          singlePageFieldsIndex: newIndex,
          singlePageProgress: Math.round((newIndex / this.state.singlePageStepFields.length) * 100),
          errors: [],
          warnings: {},
        });

        // Hide Footer text for NoFooter
        this.context?.setFooterText(
          this.state.singlePageStepFields[newIndex][0].option1 === 'NoFooter' ? '' : step.option_2,
        );
      }
    } else if (errorLength > 0) {
      this.scrollToField(firstErrorField);
    }
  }

  /** Warnings Dialog Accept Button Handler */
  async handleAcceptWarnings(): Promise<void> {
    const step = this.props.step;
    // Ignore SinglePageFormat for _edit and _review
    if (
      step.option_1 === 'SinglePageFormat' &&
      !window.location.pathname.includes('_edit') &&
      !window.location.pathname.includes('_review')
    ) {
      this.toggleWarningsModal();
      const fields = this.props.step.fields;
      const newIndex = this.state.singlePageFieldsIndex + 1;
      if (fields.length > newIndex) {
        this.setState({ singlePageFieldsIndex: newIndex, errors: [], warnings: {} });
      }
    } else {
      this.toggleWarningsModal();
      const result = await this.props.renderer.submitForm(true);
      if (result.length === 0) {
        this.props.renderer.navigate(this.state.targetRoute);
      }
    }
  }

  /**
   * render SweetAlert for warnings
   * @param isShowTitle Flag for showing title
   * @returns SweetAlert component
   */
  renderWarningDialog(isShowTitle = true): JSX.Element {
    return (
      <>
        {this.state.isShowWarningsModal && (
          <SweetAlert
            title={
              isShowTitle
                ? langService.Translate(
                    'The form contains the following warning messages. Press Accept to continue submitting or Edit Answer(s) to go edit the form.',
                  )
                : ''
            }
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Accept"
            cancelBtnText="Edit Answer(s)"
            onConfirm={() => {
              this.handleAcceptWarnings();
            }}
            onCancel={() => {
              this.toggleWarningsModal();
            }}
          >
            {Object.keys(this.state.warnings).map((key: string) => {
              return (
                <div key={'warning' + key} className={isShowTitle ? '' : 'font-size-24'}>
                  {this.state.warnings[key]}
                </div>
              );
            })}
          </SweetAlert>
        )}
      </>
    );
  }

  renderModals = (): JSX.Element => {
    return (
      <>
        <FormEditModal
          isShowEditModal={this.state.isShowEditModal}
          handleEditSave={this.handleEditSave}
          toggleEditModal={this.toggleEditModal}
          edit_items={this.state.edit_items}
          error={this.state.errors['reason']}
          activeTab={this.state.activeTab}
          renderFormField={(
            field: StepForm.FieldInfo,
            is_on_modal: boolean,
            is_disabled?: boolean,
          ) => this.renderFormField(this.props.step, field, is_on_modal, is_disabled)}
          toggleTab={this.toggleTab}
        />

        <QueryCreateModal
          isShowQueryModal={this.state.isShowQueryModal}
          handleQuerySave={this.handleQuerySave}
          toggleQueryModal={this.toggleQueryModal}
          query_item={this.state.query_item}
          error={this.state.errors['reason']}
        />

        <QueryReplyModal
          isShowQueryReplyModal={this.state.isShowQueryReplyModal}
          is_edit_mode={this.props.step.is_edit_mode}
          is_query_mode={this.props.step.is_query_mode}
          handleQueryReplySave={this.handleQueryReplySave}
          toggleQueryReplyModal={this.toggleQueryReplyModal}
          query_record={this.state.query_record}
          error={this.state.errors['message']}
        />

        {this.state.isShowQuerySuccessModal && (
          <SweetAlert
            title={langService.Translate('Query has been created, the page will now reload')}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.toggleQuerySuccessModal();
              window.location.reload();
            }}
          ></SweetAlert>
        )}
        {this.state.isShowFormLockErrorModal && (
          <SweetAlert
            title={langService.Translate('Can not lock the form with open queries')}
            error
            confirmBtnBsStyle="success"
            onConfirm={() => {
              this.toggleFormLockErrorModal();
            }}
          ></SweetAlert>
        )}
      </>
    );
  };

  public render(): JSX.Element {
    let keyNum = 1000;

    const list: any[] = [];
    this.props.items.forEach((item: any) => {
      if (item.type == 'Group' || item.type == 'ButtonMenu') {
        if (item?.children.length) {
          list.push({
            key: item.key,
            item:
              item.type == 'ButtonMenu' ? (
                <div className="button-menu">
                  <table className="button-list-table table-nowrap table-centered table-borderless table">
                    {this.renderGroupItemChildren(item?.children, keyNum++)}
                  </table>
                </div>
              ) : (
                <div className="col-md-12 form-field-group">
                  {this.renderGroupItemChildren(item?.children, keyNum++)}
                </div>
              ),
          });
        }
      } else if (item.hasOwnProperty('key')) {
        /** This is already a JSX.Element object from the main RenderHelper */
        list.push({
          key: (item as JSX.Element).key,
          item: item as JSX.Element,
          field: (item as JSX.Element).key,
        });
      } else {
        /** This is a Form Element, render it from this class */
        const comp = this.renderFormField(this.props.step, item as StepForm.FieldInfo, false);
        list.push({
          key: 'item_' + keyNum++,
          item: comp,
          field: (item as StepForm.FieldInfo).field,
        });
      }
    });

    const step = this.props.step;

    // Ignore SinglePageFormat for _edit and _review
    if (
      step.option_1 === 'SinglePageFormat' &&
      !window.location.pathname.includes('_edit') &&
      !window.location.pathname.includes('_review')
    ) {
      return (
        <div className="single-page-form">
          <Row>
            <Col>
              <div className="progress">
                <Progress bar value={this.state.singlePageProgress} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.singlePageStepFields[this.state.singlePageFieldsIndex].map((item) => (
                <div
                  className="row"
                  style={{ paddingBottom: '20px' }}
                  key={'single_page_item_' + item.key}
                  data-field={item.field}
                >
                  {item.isFormElement
                    ? this.renderFormField(this.props.step, item.item as StepForm.FieldInfo, false)
                    : item.item}
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="single-page-buttons">
                <Button
                  type="button"
                  color="primary"
                  disabled={this.state.singlePageFieldsIndex === 0}
                  onClick={() => this.handleGoBackSinglePage()}
                >
                  {langService.Translate('Back')}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  disabled={
                    this.state.singlePageFieldsIndex === this.state.singlePageStepFields.length - 1
                  }
                  onClick={() => this.handleGoNextSinglePage()}
                >
                  {langService.Translate('Next')}
                </Button>
              </div>
            </Col>
          </Row>
          {this.renderWarningDialog(false)}
          {this.renderFoldableReview()}
          {this.renderModals()}
        </div>
      );
    }

    console.log(this.props.step);

    return (
      <Row>
        <Col>
          {this.state.formLockStatus && (
            <div>
              <label>{langService.Translate('This form is currently locked')}</label>
            </div>
          )}
          {list.map((i) => (
            <div className="row" style={{ paddingBottom: '20px' }} key={i.key} data-field={i.field}>
              {i.item}
            </div>
          ))}
          {this.state.formLockStatus && (
            <label>
              {langService.Translate('Record locked on')}&nbsp;
              {moment(this.state.formLockDt).format('DD MMMM YYYY, hh:mm a')} by&nbsp;
              {this.state.formLockBy}
            </label>
          )}
        </Col>

        <div className="foldable-group">
          {this.renderFoldableReview()}
          {/* RB1 Data Monitor View */}
          {(this.props.step.is_query_mode || this.props.step.is_edit_mode) && (
            <div
              className={classNames(
                'foldable alert-warning monitor-view',
                window.location.pathname.includes('_review') ? 'stacked' : '',
              )}
            >
              <div
                className="foldable-title row justify-content-between align-items-center mr-1"
                onClick={() => {
                  this.setState({ isOpen: !this.state.isOpen });
                }}
              >
                <div>
                  <span>
                    {step.is_query_mode
                      ? langService.Translate('You are logged in as a Data Monitor.')
                      : langService.Translate('Form Query')}
                  </span>
                </div>
                <div>
                  <div className="d-inline-block">
                    <span className="action-title">
                      {this.state.isOpen
                        ? langService.Translate('Close History')
                        : langService.Translate('Show History')}
                    </span>
                    <span className="action-icon">
                      <FontAwesomeIcon
                        icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'}
                      />
                    </span>
                  </div>

                  {step.is_query_mode && (
                    <>
                      <span className="ml-3 mr-1">{langService.Translate('Lock Record')}:</span>
                      <div className="custom-control custom-switch d-inline-block mr-1">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="form-lock-record"
                          checked={this.state.formLockStatus}
                          onChange={this.handleFormLockToggle}
                        />
                        <label className="custom-control-label" htmlFor="form-lock-record"></label>
                      </div>
                    </>
                  )}
                  {this.state.formLockStatus && (
                    <span className="mr-3">{langService.Translate('Locked')}</span>
                  )}
                  {step.is_query_mode && (
                    <Button
                      className="query-form-btn"
                      type="button"
                      color="primary"
                      size="sm"
                      disabled={this.state.is_saving}
                      onClick={this.handleGeneralQuery}
                    >
                      {langService.Translate('Query Form')}
                    </Button>
                  )}
                </div>
              </div>
              <div className="foldable-content">
                <Collapse isOpen={this.state.isOpen}>
                  {this.renderLockHistory()}
                  {(step.is_query_mode || step.is_edit_mode) && (
                    <div className="row" style={{ paddingBottom: '20px' }} data-field={'FORM'}>
                      {step.formQuery.filter((record: DataRecord) => {
                        return record.data.table_field === 'FORM';
                      }).length > 0 && (
                        <label className="col-md-12 col-form-label">
                          {langService.Translate('General Record Query')}
                        </label>
                      )}
                      {this.renderFieldHistory('FORM')}
                      {this.renderFieldQueries('FORM')}
                    </div>
                  )}
                </Collapse>
              </div>
            </div>
          )}
        </div>
        {this.renderModals()}
        {this.renderWarningDialog()}
      </Row>
    );
  }
}

export default PageForm;
