import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import { RemoteAgent, RemoteAgentConnectionType } from '@lainaedge/platformshared';
import { useData } from 'Common/context/DataContext';
import { printEDCLog } from 'Common/helpers';
import moment from 'moment';

import SessionRecordsModal from '../../components/SessionRecordsModal';

import './index.scss';

const test_pid = '9994-424-317';
const test_password = 'laina123';
const env = 'uat';
const project = 'rb2';

const cookies = new Cookies();

const remote = RemoteAgent.instance();

const records: Array<RemoteAgentConnectionType> = [];
const allRecords: Array<RemoteAgentConnectionType> = [];

/**
 * Admin/pages/TrialWatch component.
 *
 * @remarks
 * TrialWatch that runs remoteAgent
 *
 * @component TrialWatch
 * @category Page
 */
const TrialWatch = (): JSX.Element =>
{
  const { setPageTitle } = useData();
  const [status, setStatus] = useState('waiting_for_device');
  const [timestamp, setTimestamp] = useState(new Date());
  const [showUAT, setShowUAT] = useState(cookies.get('laina_trialwatch_show_uat') || true);
  const [showProd, setShowProd] = useState(cookies.get('laina_trialwatch_show_prod') || true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState('');

  /** When a session is found that we want to debug, send the login. */
  // eslint-disable-next-line
  async function startSession(conn: RemoteAgentConnectionType)
  {
    printEDCLog('DemoRemoteAgentSend: Sending login request as participant', test_pid);

    await remote.sendCommandEnv(conn.session_id, env, project);
    await remote.sendCommandLoginParticipant(conn.session_id, test_pid, test_password);

    // The remote should be at the dashboard or whatever page is loaded after login.
    setStatus('complete');

    return true;
  }

  useEffect(() =>
  {
    setPageTitle('TrialWatch')
    //remote.setStatus("DebugDemo");

    /** Initialize the remote agent connection and watch for announcements from connected
      clients.  Once a client updates or connects it will send a ping.*/
    remote.startRemoteAgent();

    //remote.setStatus('DebugDemo');

    // Set handler for incoming ping information
    remote.cbPing = (conn: RemoteAgentConnectionType) =>
    {
      printEDCLog('Received info on:', conn);

      const found = records.find((log) => log.session_id === conn.session_id);
      allRecords.push(conn);

      if (found)
      {
        const index = records.findIndex((log) => log.session_id === conn.session_id);
        records.splice(index, 1, { ...found, ...conn });
      } else
      {
        records.unshift(conn);
      }

      setTimestamp(new Date());

      if (status === 'waiting_for_device')
      {
        printEDCLog('Found session to debug, session id = ', conn.session_id);

        // Start a session using this connection for debugging
        // start by sending login information as a participant
        // startSession(conn).then(()=> {
        // 	setStatus('complete2');
        // });
      } else
      {
        printEDCLog('Not a match, status=', status);
      }
    };

    // Set handler for page updates
    remote.cbPageUpdate = (update: any) =>
    {
      printEDCLog('PAGE STATUS:', update);
    };

    // Initialize reader to listen
    remote.watchForConnections();

    // Ask any clients currently running to ping
    remote.sendHeartbeatRequest();

    //Implementing the setInterval method
    const interval = setInterval(() =>
    {
      setTimestamp(new Date());
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, []);

  const toggleUAT = (e: React.ChangeEvent<HTMLInputElement>) =>
  {
    if (e.target.checked)
    {
      cookies.set('laina_trialwatch_show_uat', e.target.checked, { path: '/' });
    } else
    {
      cookies.remove('laina_trialwatch_show_uat', { path: '/' });
    }
    setShowUAT(e.target.checked);
  }

  const toggleProd = (e: React.ChangeEvent<HTMLInputElement>) =>
  {
    if (e.target.checked)
    {
      cookies.set('laina_trialwatch_show_prod', e.target.checked, { path: '/' });
    } else
    {
      cookies.remove('laina_trialwatch_show_prod', { path: '/' });
    }
    setShowProd(e.target.checked);
  }

  const handleSelectSession = (sessionId: string) =>
  {
    setSelectedSessionId(sessionId);
    setIsShowModal(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="pt-2 pb-2">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="show-uat"
                checked={showUAT}
                onChange={toggleUAT}
              />
              <label className="custom-control-label" htmlFor="show-uat">
                Show UAT
              </label>
            </div>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="show-prod"
                checked={showProd}
                onChange={toggleProd}
              />
              <label className="custom-control-label" htmlFor="show-prod">
                Show Prod
              </label>
            </div>
          </div>
          <table className="table sortable-table trial-watch-table">
            <thead>
              <tr>
                <td className="col-session">Session ID</td>
                <td className="col-env">ENV</td>
                <td className="col-project">Project</td>
                <td className="col-ip">IP</td>
                <td>PID</td>
                <td>Page/Event</td>
                <td>Status</td>
                <td>Last Seen</td>
                <td>Local Time</td>
              </tr>
            </thead>
            <tbody>
              {records.filter((log: RemoteAgentConnectionType) =>
              {
                if (!showUAT && log.env == 'uat')
                {
                  return false;
                }
                if (!showProd && log.env == 'prod')
                {
                  return false;
                }
                return true;
              }).sort((a: any, b: any) =>
              {
                const lastSeenA = a?.local_time ? a.local_time : a.start;
                const lastSeenB = b?.local_time ? b.local_time : b.start;
                if (lastSeenA > lastSeenB)
                {
                  return -1;
                }
                if (lastSeenA < lastSeenB)
                {
                  return 1;
                }
                return 0;
              }).map((log: any, index: number) =>
              {
                const lastSeen = moment().diff(moment(log?.local_time ? log?.local_time : log.start), 'seconds');
                const lastSeenMinutes = Math.round(lastSeen / 60);
                const lastSeenStr = lastSeen < 120 ? `${lastSeen} seconds` : `${lastSeenMinutes} minutes`
                if (lastSeenMinutes > 30)
                {
                  return;
                }
                const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
                return (
                  <tr key={'log' + index}>
                    <td className="cursor-pointer col-session" onClick={() => handleSelectSession(log.session_id)}>{log.session_id}</td>
                    <td className="col-env">{log.env}</td>
                    <td className="col-project">{log.project}</td>
                    <td className="col-ip">{log.ip}</td>
                    <td>{log.pid ? log.pid : log.user}</td>
                    <td>{log.page}</td>
                    <td>{log.status}</td>
                    <td>{lastSeenStr}</td>
                    <td>{timezone}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <SessionRecordsModal
        isOpen={isShowModal}
        toggleEditModal={() => { setIsShowModal(!isShowModal) }}
        records={allRecords.filter((log: RemoteAgentConnectionType) => log.session_id == selectedSessionId)}
      />
    </React.Fragment>
  );
};

export default TrialWatch;
