import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { NavigateFunction } from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';

import
{
  DataServices,
  LangServices,
  LogicEngine,
  PageEngine,
  PageManager,
  RemoteAgent,
} from '@lainaedge/platformshared';
import { SystemProblemEventType } from '@lainaedge/platformshared/lib/utils/DataServices';
import
{
  NotificationInitializeEvent,
  NotificationSubscribeEvent,
} from '@lainaedge/platformshared/lib/utils/NotificationManager';
// See https://pusher.com/docs/beams/getting-started/web/sdk-integration/
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import Breadcrumb from 'Common/components/Breadcrumb';
import { myProjectName } from 'Common/constants';
import { MenuItem } from 'Common/constants/types';
import { DataContext } from 'Common/context/DataContext';
import { printEDCLog } from 'Common/helpers';
import { getBookmark } from 'Common/services';
import { RenderHelper } from 'PageEngine/components/RenderHelper';

const cookies = new Cookies();

const dataService = DataServices.instance();
const langService = LangServices.instance();

enum TPageState
{
  Loading,
  Finished,
  Error,
  RouteMissing,
}

/**
 * Props for [[`MainPageRender`]] component
 */
interface MainPageRenderProps
{
  location: { pathname: string };
  navigate: NavigateFunction;
  // context: IDataContext;
}

/**
 * State for [[`MainPageRender`]] component
 */
interface MainPageRenderState
{
  allComponents: JSX.Element[];
  pageState: TPageState;
  pageTitle?: string;
  metaTitle?: string;
  metaDescription?: string;
  parentTitle?: string;
  pTitle?: string;
  version?: string;
}

type IntlTitleProps = 'title_en' | 'title_es' | 'title_hi';

function getTitlePropName(lang: string): IntlTitleProps
{
  if (lang === 'es')
  {
    return 'title_es';
  }
  if (lang === 'hi')
  {
    return 'title_hi';
  }
  return 'title_en';
}

function capitalizeFirstLetter(str: string)
{
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * MainPageRender component.
 *
 * @remarks
 * Main renderer that parses the Mobile Config and renders the UI
 *
 * @component MainPageRender
 * @category Component
 */
class MainPageRender extends Component<MainPageRenderProps, MainPageRenderState> {
  static contextType = DataContext;

  /**
   * useEffect is called when the class is loaded.  we'll use that to pull down
   * the mobile config json data needed for page engine.
   */
  async componentDidMount()
  {
    const defaultLang = new URLSearchParams(window.location.search).get('L') || '';
    if (defaultLang)
    {
      langService.setLanguage(defaultLang);
    }

    const token = cookies.get('token');

    if (token)
    {
      const resp = await dataService.authUserFromToken(token);

      if (!resp.isValidLogin)
      {
        cookies.remove('authUser', { path: '/' });
      }
    }

    await this.pageLoad();

    DataServices.evSystemProblem.subscribe((systemException: SystemProblemEventType) =>
    {
      console.dir(systemException);
      if (systemException.event_type === 'token')
      {
        cookies.remove('authUser', { path: '/' });
        cookies.remove('token', { path: '/' });
        window.location.href = '/login';
      }
    });
  }

  async componentDidUpdate(prevProps: MainPageRenderProps)
  {
    if (this.props.location.pathname !== prevProps.location.pathname)
    {
      await this.pageLoad();
    }
  }

  setupNotificationManager(page: PageEngine)
  {
    page.notificationManager.evInitializeClient.subscribe((data: NotificationInitializeEvent) =>
    {
      printEDCLog('Notification Engine Initialize', data);
    });

    page.notificationManager.evSubscribe.subscribe((data: NotificationSubscribeEvent) =>
    {
      printEDCLog('Notification Engine Subscribe to:', data.topic);

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: data.instanceKey,
      });

      beamsClient.start().then(() =>
      {
        beamsClient
          .getDeviceId()
          .then((deviceid) =>
          {
            page.notificationManager.setDeviceID(deviceid);
            printEDCLog('Successly got device:', deviceid);

            beamsClient.addDeviceInterest(data.topic);
          })
          .then(() =>
          {
            // get the current list of topics we are subscribed to
            beamsClient.getDeviceInterests().then((myList) =>
            {
              page.notificationManager.setMyTopics(myList);
            });
          });
      });
    });
  }

  async pageLoad()
  {
    /** Load page by route from PageManager */
    const { navigate } = this.props;

    const routeName = this.props.location.pathname;

    PageManager.allowEdcConfig = true;
    PageManager.allowMobileConfig = true;
    const page = await PageManager.instance().findRoute(routeName);

    const { lang, menuItems, versionInfo, title_text, config } = this.context;

    /** get the redirectUrl from cookie and redirect to the url if it is valid and remove cookie*/
    if (cookies.get('redirectUrl'))
    {
      const redirectUrl: string = cookies.get('redirectUrl');
      cookies.remove('redirectUrl', { path: '/' });

      if (redirectUrl !== '/page/portaldashboard')
      {
        /** if the redirectUrl is not '/page/portaldashboard', proceeds the redirect */

        navigate(redirectUrl);
        return;
      }
    }

    /** Reset Footer to original config footer_text */
    if (config && config?.tables && config?.tables?.WebsiteConfig)
    {
      let footer_text = new Date().getFullYear() + ' © Laina';
      const WebsiteConfig = config.tables.WebsiteConfig;
      const settings = WebsiteConfig[0].data;
      // build Site Config
      if (settings)
      {
        const footer: any = settings.find((item: any) => item.setting === 'Footer_text');
        if (footer) footer_text = footer.value;
      }
      this.context?.setFooterText(footer_text);
    }

    this.setupNotificationManager(page);

    page.evInitializePage.subscribe(async (metaData: any) =>
    {
      let parentTitle: string | null = '';
      menuItems.map((parent: MenuItem) =>
      {
        parent.children.map((child) =>
        {
          if (child.route === metaData.route) parentTitle = parent.title_en;
          return child;
        });
        return parent;
      });

      const pTitle = capitalizeFirstLetter(metaData[getTitlePropName(lang)]!);

      if (pTitle)
      {
        this.setState({
          pageState: TPageState.Loading,
          pTitle: pTitle,
          pageTitle:
            pTitle +
            ' | V' +
            (this.state.version ? this.state.version : versionInfo.version) +
            ' | ' +
            title_text,
          metaTitle: metaData[getTitlePropName(lang)],
          metaDescription: metaData['description'],
          parentTitle,
        });

        this.context.setPageTitle(pTitle);
        this.context.setBookmarkTitle(pTitle);
      }

      const authUser = cookies.get('authUser');
      getBookmark(authUser.username, this.props.location.pathname, myProjectName.toLowerCase())
        .then((res: any) =>
        {
          if (res.data && res.data.length)
          {
            this.context.setBookmark(true);
          } else
          {
            this.context.setBookmark(false);
          }
        })
        .catch((e: any) =>
        {
          this.context.setBookmark(false);
        });
      // this.context.setBreadCrumbs({
      //   parentTitle: parentTitle,
      //   childTitle: metaData[getTitlePropName(lang)],
      //   listLink: metaData.route,
      // });
    });

    page.evForceRefresh.subscribe((metaData: any) =>
    {
      /** When page refresh event emitted, update the side menu counts */
      this.context.setMenuUpdate(true);
    });

    page.evFinishedPage.subscribe((metaData: any) =>
    {
      /** On finishing page load, update the pageState state to hide the loading spinner */
      this.setState({ pageState: TPageState.Finished });
    });

    if (page && page.isValid())
    {
      LogicEngine.debugWebdriver = navigator.webdriver;
      if (navigator.webdriver) RemoteAgent.enabled = false;

      const rend_helper = new RenderHelper();

      rend_helper.navigate = navigate;
      rend_helper.location = this.props.location;
      rend_helper.context = this.context;
      await page.processPage(rend_helper);
      const list: JSX.Element[] = [];
      rend_helper.currentTarget.forEach((e) =>
      {
        list.push(e as JSX.Element);
      });

      this.setState({
        allComponents: list,
      });

      // set LogicValues to show in Gear Modal
      const logicRef = rend_helper?.logicRef;

      const pairs: Array<any> = [];
      if (logicRef)
      {
        Object.keys(logicRef.values).map((key) =>
        {
          if (['FALSE', 'TRUE', 'NULL', 'null', 'nil'].includes(key))
          {
            return;
          }
          if (typeof logicRef.values[key] !== 'function')
          {
            pairs.push({
              key,
              value: logicRef.values[key],
            });
          }
        });
      }

      this.context.setLogicValues(pairs);
    } else
    {
      // Error condition, unable to find page
      this.setState({ pageState: TPageState.RouteMissing });
    }
  }

  public constructor(props: MainPageRenderProps)
  {
    super(props);
    this.state = {
      allComponents: [],
      pageState: TPageState.Loading,
      pageTitle: '',
      metaTitle: '',
      parentTitle: '',
    };
  }

  public render(): JSX.Element
  {
    const { pageState, metaTitle, metaDescription, pageTitle, allComponents } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta name="title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
        </Helmet>
        <div className="page-content">
          {pageState === TPageState.Loading ? (
            <Container fluid className="loader-container">
              <Spinner className="mr-2" color="primary" />
            </Container>
          ) : (
            <>
              <Container fluid>
                <Breadcrumb />
                {allComponents}
              </Container>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MainPageRender;
