import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { DataQuery } from '@lainaedge/platformshared';
import { MenuItem } from 'Common/constants/types';
import { useData } from 'Common/context/DataContext';

type IntlTooltipProps = 'tooltip_en';

const dataQuery = DataQuery.instance();

interface SidebarMenuLinkProps
{
  link: MenuItem;
  label: string;
  index: string;
}

const SidebarMenuLink = ({ link, label, index }: SidebarMenuLinkProps) =>
{
  const location = useLocation();
  const { lang } = useData();

  const [count, setCount] = useState<number | null>(null);

  function getTooltipPropName(lang: string): IntlTooltipProps
  {
    return lang === 'en' ? 'tooltip_en' : 'tooltip_en';
  }

  const isActive = useMemo(() => location.pathname === link.route, [location.pathname, link.route]);

  useEffect(() =>
  {
    const getBadgeCount = async () =>
    {
      if (link.badge_query)
      {
        const result = await dataQuery.executeCountQueryByText(link.badge_query);
        setCount(result);
      }
    };
    getBadgeCount();
  }, []);

  return (
    <React.Fragment>
      <li className={isActive ? 'mm-active' : ''}>
        {link[getTooltipPropName(lang)] && (
          <UncontrolledTooltip placement="top" target={`tooltip-${index}`}>
            {link[getTooltipPropName(lang)]}
          </UncontrolledTooltip>
        )}
        <Link to={link.route!} className={isActive ? 'active' : ''} id={`tooltip-${index}`}>
          <p>
            <i className={link.icon!} />
            <span className="font-size-15">{label}</span>
          </p>
          {link.badge_query && (
            <span className="badge badge-pill badge-primary float-right font-size-12">{count}</span>
          )}
        </Link>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenuLink;
