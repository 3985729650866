import React from 'react';

import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';

/**
 * TimeInput component
 *
 * @component TimeInput
 * @category FormElements
 */
export default class TimeInput extends FormInput
{
  /**
   * Renders TimeInput class component.
   */
  public render(): JSX.Element
  {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <input
            className="form-control"
            type="time"
            name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
            value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
            disabled={this.isFieldDisabled()}
            onChange={(e: any) =>
            {
              if (this.isEditFieldOnModal())
              {
                this.props.formProps.handleChangeEditValues(field, e.target.value);
              } else
              {
                this.handleChangeText(field, e);
              }
            }}
          />
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
