import React from 'react';

import { LangServices, StepForm } from '@lainaedge/platformshared';
import { LookupType } from '@lainaedge/platformshared/lib/types/DataDictionaryType';

import FormInput from './FormInput';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * MultiSelect component
 *
 * @component MultiSelect
 * @category FormElements
 */
export default class MultiSelect extends FormInput {
  /**
   * Used to change the value of a multiple select field.
   *
   * @remarks
   * If the current field value matches the new value then it sets state
   * and form value without it, otherwise adds it to the current value.
   *
   * @param field - Parser to the field.
   * @param e - New value to add or remove.
   * @returns Void
   */
  handleChangeRadio = (field: StepForm.FieldInfo, e: any) => {
    const step = this.props.formProps.step;

    if (this.isEditFieldOnModal()) {
      // Edit mode
      const valueArray = this.props.formProps.edit_values[field.field]
        .split(',')
        .filter((v: any) => {
          return v !== '';
        });
      const newValue = valueArray.includes(e.target.value)
        ? valueArray.filter((v: any) => {
            return v !== e.target.value;
          })
        : [...valueArray, e.target.value.toString()];
      this.props.formProps.handleChangeEditValues(field, newValue.join(','));
    } else {
      // Create mode
      const myValueDatabase = step.getValueDatabase(field.field);

      const valueArray = myValueDatabase.split(',').filter((v: any) => {
        return v !== '';
      });

      const newValue = valueArray.includes(e.target.value)
        ? valueArray.filter((v: any) => {
            return v !== e.target.value;
          })
        : [...valueArray, e.target.value];

      this.setState({ myFieldValue: newValue.join(',') });
      this.props.formProps.step.setValueFromUser(field.field, newValue.join(','));
    }
  };

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  getLookupItemText = (item: any) => {
    const lang = LangServices.currentLanguage.short_code;
    return item['text_' + lang];
  };

  /**
   * Renders MultiSelect class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    if (step.tableDef && step.dataDictionary) {
      const name = step.tableDef.getFieldLookupName(field.field);
      const list: LookupType[] = step.dataDictionary.getLookupTable(name);

      const myValueDatabase = step.getValueDatabase(field.field);

      const valueArray = myValueDatabase.split(',');

      const editValueArray = this.props.formProps.edit_values[field.field]?.split(',');

      return (
        <>
          {list
            ? list.map((item: LookupType) => (
                <div className="custom-control custom-checkbox mb-3" key={item.code}>
                  <input
                    type="checkbox"
                    id={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field + item.code
                        : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                    }
                    className="custom-control-input"
                    name={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field
                        : this.isEditMode()
                        ? 'm' + field.field
                        : field.field
                    }
                    value={item.code}
                    checked={
                      this.isEditFieldOnModal()
                        ? editValueArray.includes(item.code)
                        : this.props.formProps.hide_fields[field.field]
                        ? false
                        : valueArray.includes(item.code)
                    }
                    disabled={this.isFieldDisabled()}
                    onChange={(e: any) => {
                      this.handleChangeRadio(field, e);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={
                      this.isEditFieldOnModal()
                        ? 'e' + field.field + item.code
                        : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                    }
                  >
                    {htmlToReactParser.parse(this.getLookupItemText(item))}
                  </label>
                </div>
              ))
            : ''}
          {this.renderQnsAndUnavailableSwitches()}
          {this.renderValidationError()}
        </>
      );
    }

    return <></>;
  }
}
