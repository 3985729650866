import
{
    ChangePassword as ChangePwd,
    DefaultPage,
    TrialWatch
} from 'Admin';
import
{
    CreateMagicLink as CreateMagicLink,
    ForgetPassword as ForgetPwdPage,
    HubMagicLoginPage as HubMagicLoginPage,
    Login,
    PhoneLoginPage
} from 'Authentication/index';
import MainPageRender from 'PageEngine/components/MainPageRender';
import
{
    EnterPassword as ParticipantEnterPassword,
    ForgotPassword as ParticipantForgotPassword,
    SetPassword as ParticipantSetPassword,
    Study as ParticipantStudy,
} from 'Participant';

const guestRoutes = [
    // For Data Monitors
    { path: '/login', component: Login },
    { path: '/mobile-login', component: PhoneLoginPage },
    { path: '/forgot-password', component: ForgetPwdPage },
    { path: '/magic/:code', component: HubMagicLoginPage },
    { path: '/newMagic', component: CreateMagicLink },

    // For Participants
    { path: '/study/setPassword/:pid', component: ParticipantSetPassword },
    { path: '/study/enterPassword/:pid', component: ParticipantEnterPassword },
    { path: '/study/forgotPassword/:pid', component: ParticipantForgotPassword },
    { path: '/study', component: ParticipantStudy },
    { path: '/loading', component: DefaultPage },
    // { path: '/system/magicPage', component: MagicPage },
];

const internalRoutes = [
    { path: '/changepassword', component: ChangePwd },
    { path: '/page/:page', component: MainPageRender },
    { path: '/:task/page/:page', component: MainPageRender },
    { path: '/_edit/:task/page/:page', component: MainPageRender },
    { path: '/_edit/page/:page', component: MainPageRender },
    { path: '/_edit/page/:page/:pid/:id', component: MainPageRender },
    { path: '/_editqueryid', component: MainPageRender },
    { path: '*', component: MainPageRender },
    { path: '/trialwatch', component: TrialWatch },
];

export
{
    guestRoutes,
    internalRoutes
};