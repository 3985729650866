import React from 'react';
import { Modal } from 'reactstrap';

import { RemoteAgentConnectionType } from '@lainaedge/platformshared';
import moment from 'moment';

import './index.scss';

/**
 * Props for [[`SessionRecordsModal`]] component
 */
interface ModalProps
{
    isOpen: boolean | undefined;
    toggleEditModal: () => void;
    records: Array<RemoteAgentConnectionType>;
}

/**
 * Admin/components/SessionRecordsModal component
 *
 * @component SessionRecordsModal
 * @category Component
 */
const SessionRecordsModal = ({
    isOpen,
    toggleEditModal,
    records
}: ModalProps): JSX.Element =>
{

    const handleCloseModal = () =>
    {
        toggleEditModal();
    };

    console.log(records);

    return (
        <Modal size="lg" isOpen={isOpen} contentClassName='session-history-modal'>
            <div className="modal-header">
                <h5 className="modal-title mt-0">Session History Records</h5>
                <button
                    type="button"
                    onClick={handleCloseModal}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <table className="table sortable-table">
                    <thead>
                        <tr>
                            <td>Session ID</td>
                            <td>ENV</td>
                            <td>Project</td>
                            <td>IP</td>
                            <td>PID</td>
                            <td>Page/Event</td>
                            <td>Status</td>
                            <td>Last Seen</td>
                            <td>Local Time</td>
                        </tr>
                    </thead>
                    <tbody>
                        {records.sort((a: any, b: any) =>
                        {
                            const lastSeenA = a?.local_time ? a.local_time : a.start;
                            const lastSeenB = b?.local_time ? b.local_time : b.start;
                            if (lastSeenA > lastSeenB)
                            {
                                return -1;
                            }
                            if (lastSeenA < lastSeenB)
                            {
                                return 1;
                            }
                            return 0;
                        }).map((log: any, index: number) =>
                        {
                            const lastSeen = moment().diff(moment(log?.local_time ? log?.local_time : log.start), 'seconds');
                            const lastSeenMinutes = Math.round(lastSeen / 60);
                            const lastSeenStr = lastSeen < 120 ? `${lastSeen} seconds` : `${lastSeenMinutes} minutes`
                            // if (lastSeenMinutes > 30)
                            // {
                            //   return;
                            // }
                            const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
                            return (
                                <tr key={'log' + index}>
                                    <td>{log.session_id}</td>
                                    <td>{log.env}</td>
                                    <td>{log.project}</td>
                                    <td>{log.ip}</td>
                                    <td>{log.pid}</td>
                                    <td>{log.page}</td>
                                    <td>{log.status}</td>
                                    <td>{lastSeenStr}</td>
                                    <td>{timezone}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Modal>
    );
};
export default SessionRecordsModal;
