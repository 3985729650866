import React from 'react';
import Autosuggest from 'react-autosuggest';

import { StepForm } from '@lainaedge/platformshared';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';
import { InputProps } from './types';

/**
 * AutoSuggestInput component @extends FormInput
 *
 * @component AutoSuggestInput
 * @category FormElements
 */
export default class AutoSuggestInput extends FormInput
{
    constructor(props: InputProps)
    {
        super(props);

        /** Initialize the value of the state from the database value */
        const field = this.props.formProps.field;

        this.state = {
            myAlign: this.props.formProps.step.getValueAlign(field.field),
            myUnits: this.props.formProps.step.getValueUnits(field.field),
            myFieldValue: this.getValue(field),
            suggestions: []
        };
    }

    /**
     * Used to change the value of a field.
     *
     * @param field - Points to the field.
     * @param e - Used to set the value.
     * @returns Void
     */
    handleChangeAutoSuggestInput = (field: StepForm.FieldInfo, e: any) =>
    {
        if (e.target.value === undefined)
        {
            return;
        }
        if (this.isEditFieldOnModal())
        {
            this.props.formProps.handleChangeEditValues(field, e.target.value);
        } else
        {
            this.setValue(field, e.target.value);
        }
    };

    /**
     * Called when the user selectes a value from the suggestions list
     *
     * @param event - Event data.
     * @param suggestion - Suggestion object
     * @param suggestionValue - Suggestion string value
     * @returns Void
     */
    onSuggestionSelected = (event: any, { suggestion, suggestionValue }: any) =>
    {
        const { field, step } = this.props.formProps;
        if (step && step.tableDef && step.dataDictionary)
        {
            /** Represents the options of the field */
            const name = step.tableDef.getFieldLookupName(field.field);

            /** Represents the lookup table */
            const list = step.dataDictionary.getLookupTable(name);

            /** Represents the first item in the list which english text is equal to value. */
            const selected = list.find((item) => item.english === suggestionValue);

            if (this.isEditFieldOnModal())
            {
                this.props.formProps.handleChangeEditValues(field, suggestionValue);
            } else
            {
                this.props.formProps.step.setValueFromUser(field.field, selected ? selected.code : '');

                this.setState({ myFieldValue: suggestionValue });
            }
        }
    }

    onSuggestionsFetchRequested = ({ value }: any) =>
    {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    }

    onSuggestionsClearRequested = () =>
    {
        this.setState({
            suggestions: this.getSuggestions('')
        });
    };

    getSuggestions = (value: any) =>
    {
        const { step, field } = this.props.formProps;
        if (step.tableDef && step.dataDictionary)
        {
            const name = step.tableDef.getFieldLookupName(field.field);
            const list = step.dataDictionary.getLookupTable(name);
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;

            return inputLength === 0 ? list : list.filter(lang =>
                lang.english.toLowerCase().slice(0, inputLength) === inputValue
            );
        }
        return [];
    };


    /**
     * Renders AutoSuggestInput class component.
     */
    public render(): JSX.Element
    {
        const { step, is_disabled, field } = this.props.formProps;

        if (step.tableDef && step.dataDictionary)
        {
            const fieldValue = this.isEditFieldOnModal()
                ? this.props.formProps.edit_values[field.field]
                : this.state.myFieldValue;
            return (
                <>
                    <div className="d-inline-block mr-2 mb-1">
                        <Autosuggest
                            suggestions={this.state.suggestions!}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={(item: any) => item.english}
                            onSuggestionSelected={this.onSuggestionSelected}
                            shouldRenderSuggestions={(value, reason) =>
                            {
                                return value.trim().length > 0 || reason == 'input-focused' || reason == 'render';
                            }}
                            renderSuggestion={(item: any, { isHighlighted }) =>
                            {
                                return (
                                    <div
                                        key={item.english}
                                        style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '5px' }}
                                    >
                                        {item.english}
                                    </div>
                                )
                            }}
                            inputProps={{
                                className: 'form-control autocomplete',
                                id: `autosuggest-${field.field}`,
                                disabled:
                                    this.isEditMode() ||
                                    is_disabled ||
                                    this.props.formProps.hide_fields[field.field] ||
                                    this.props.formProps.field.enabled === false,
                                name: this.isEditFieldOnModal() ? 'e' + field.field : field.field,
                                value: [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue,
                                onChange: (e: any) => { this.handleChangeAutoSuggestInput(field, e); }
                            }}
                        />
                    </div>
                    {this.renderQnsAndUnavailableSwitches()}
                    {this.renderValidationError()}
                </>
            );
        } else
        {
            return <></>;
        }
    }
}
