import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, Spinner } from 'reactstrap';

import { DataServices,LangServices } from '@lainaedge/platformshared';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';
import { useAuth } from 'Common/context/AuthContext';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const cookies = new Cookies();
interface IEnterPasswordFormFields
{
  password: string;
}

const langService = LangServices.instance();
const dataService = DataServices.instance();

/**
 * Participant/EnterPassword component.
 *
 * @remarks
 * Page where participant enters password to log in
 *
 * @component EnterPassword
 * @category Page
 */
const EnterPassword = (): JSX.Element =>
{
  const navigate = useNavigate();
  const { pid } = useParams() as { pid: string };
  const { setUser, setUserType, setIsAuthenticated } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMagicLinkSent = false;

  const mounted = useRef(false);
  useEffect(() =>
  {
    mounted.current = true;
    return () =>
    {
      mounted.current = false;
    };
  });

  async function handleValidSubmit(values: IEnterPasswordFormFields)
  {
    SetIsSubmitting(true);
    const result: ParticipantAuthResult = await dataService.authParticipant(pid, values.password);

    if (result.success)
    {
      const userObj = {
        username: pid,
        email: pid,
        userType: 'participant',
        token: result.authToken!,
        groups: [],
      };
      cookies.set('authUser', userObj, { path: '/' });
      cookies.set('token', result.authToken, { path: '/' });
      cookies.set('type', 'participant', { path: '/' });
      setUser(userObj);
      setIsAuthenticated(true);
      setUserType('participant');
      const IsOtherLang = LangServices.currentLanguage.short_code !== 'en';
      window.location.href = '/page/participant_start/' + pid + (IsOtherLang ? '?L=' + LangServices.currentLanguage.short_code : '');
      // navigate('/page/participant_start/' + pid);
    } else
    {
      setErrorMessage(langService.Translate(result.errorMessage!));
    }

    // component is unmounted
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          password: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(langService.Translate('Enter Password')),
        })}
        onSubmit={(values) =>
        {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">{langService.Translate('Check your email')}!</h3>
                <div className="mt-1 text-center">
                  <span>{langService.Translate('We emailed a magic link to testemail@test.com')}</span>
                  <br />
                  <span>{langService.Translate('Click the link to log in or sign up')}</span>
                </div>
              </>
            )}

            {!isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">{langService.Translate('Enter Password')}</h3>
                {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                <div className="form-group">
                  <Field
                    name="password"
                    id="password"
                    type="password"
                    required
                    placeholder={langService.Translate('Password')}
                    className={
                      'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {langService.Translate('Login')}
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </div>
              </>
            )}

            <div className="mt-3">
              <Link
                className="btn btn-light-login btn-block waves-effect waves-light save-btn"
                to="/contact"
              >
                {langService.Translate('Contact Trial Support')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EnterPassword;
