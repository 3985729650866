import React from 'react';
import { InputGroup } from 'reactstrap';

import classNames from 'classnames';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';

/**
 * NumberInput component
 *
 * @component NumberInput
 * @category FormElements
 */
export default class NumberInput extends FormInput
{
  /**
   * Renders NumberInput class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    let className = 'form-control';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';

    return (
      <>
        <div
          className={classNames(
            'd-inline-block mr-2 mb-1',
            this.isFieldDisabled() ? 'disabled' : '',
          )}
        >
          <InputGroup>
            <input
              className={className}
              type="number"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              disabled={this.isFieldDisabled()}
              onChange={(e: any) =>
              {
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(field, e.target.value);
                } else
                {
                  this.handleChangeText(field, e);
                }
              }}
            />
            {this.renderUnit()}
          </InputGroup>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
