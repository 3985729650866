import React, { Component } from 'react';

import { LangServices, StepList, StringOptions } from '@lainaedge/platformshared';
import { isJson } from 'Common/helpers';
import { RenderHelper } from 'PageEngine/components/RenderHelper';
import styled from 'styled-components';

const ListItem = styled.tr`
  cursor: pointer;
`;

const NoteListItem = styled.li`
  cursor: pointer;
`;

const langService = LangServices.instance();

/**
 * Props for [[`PageListItem`]] component
 */
export interface PageListItemProps
{
  /** Step data */
  step: StepList;
  /** Task data */
  item: any;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageListItem component
 *
 * @component PageListItem
 * @category PageElements
 */
export default class PageListItem extends Component<PageListItemProps> {
  constructor(props: PageListItemProps)
  {
    super(props);

    this.state = {};
  }

  /**
   * NoteListItem click handler, used to redirect.
   */
  handleClick = async () =>
  {
    const item = this.props.item;

    this.props.renderer.navigate(item.click);
  };

  /**
   * Used to prevent the onChange event.
   *
   * @param e - Event object
   * @returns Promise
   */
  handleFormLock = async (e: any) =>
  {
    e.stopPropagation();
  };

  /**
   * Renders PageListItem class component.
   */
  public render(): JSX.Element
  {
    const item = this.props.item;

    const style = this.props.step.listOptions.style;
    let barClass = '';
    if (item.TaskStatus === 'Completed')
    {
      barClass += ' bg-success';
    } else if (item.TaskStatus === 'Expired')
    {
      barClass += ' bg-danger';
    } else if (item.TaskStatus === 'Available')
    {
      barClass += ' bg-primary';
    } else if (item.TaskStatus === 'Reviewed')
    {
      barClass += ' bg-info';
    } else if (item.TaskStatus === 'Needs Site Review' || item.TaskStatus === 'Needs To Be Done')
    {
      barClass += ' bg-pink';
    } else
    {
      barClass += ' bg-default';
    }

    const opt = new StringOptions(this.props.step.option_1);

    if (style === 'NewTaskList')
    {
      const renderLockStatus = opt.getValue('ShowLocked', 'false') == 'true';
      const renderReviewStatus = opt.getValue('ShowReview', 'false') == 'true';
      let taskStatusClass = 'mr-1 ';
      if (['Pending'].includes(item.TaskStatus))
      {
        taskStatusClass += 'task-alert fas fa-loader';
      }
      if (['Available'].includes(item.TaskStatus))
      {
        taskStatusClass += 'task-primary fas fa-loader';
      }
      if (['Needs Site Review', 'Needs To Be Done'].includes(item.TaskStatus))
      {
        taskStatusClass += 'task-pink fas fa-loader';
      }
      if (['Completed', 'Complete'].includes(item.TaskStatus))
      {
        taskStatusClass += 'task-success fas fa-check';
      }
      if (['Reviewed', 'Review'].includes(item.TaskStatus))
      {
        taskStatusClass += 'task-info fas fa-check';
      }

      return (
        <ListItem data-testid="new-task-list-item" onClick={this.handleClick}>
          <td className={barClass} style={{ width: '5px', padding: 0, margin: 0 }}></td>
          <td className={`noborder`}></td>
          <td className="text-left">
            <span>
              <i className={item.TaskIcon + ' task-icon font-size-24 mr-2'}></i>
            </span>
            <span className={`text-wrap font-size-20 mb-0`}>{item.TaskTitle}</span>
          </td>
          <td className="text-right">
            <span className={`font-size-14 pending`}>
              <i className={taskStatusClass}></i>
              <span className="mobile-hide">{item.TaskStatus}</span>
            </span>
            {renderLockStatus && (
              <>
                <span className="border-line"></span>
                <span className={`font-size-14 lock-status`}>
                  <i
                    className={
                      item.lockstatus === 'true'
                        ? 'task-alert far fa-lock mr-1'
                        : 'task-success far fa-unlock mr-1'
                    }
                  ></i>
                  <span className="mobile-hide">
                    {item.lockstatus === 'true' ? langService.Translate('Locked') : langService.Translate('Unlocked')}
                  </span>
                </span>
              </>
            )}
            {renderReviewStatus && (
              <>
                <span className="border-line"></span>
                <span className={`font-size-14 review-status`}>
                  <i
                    className={
                      item.reviewstatus === 'true'
                        ? 'task-success far fa-eye mr-1'
                        : 'task-alert far fa-eye-slash mr-1'
                    }
                  ></i>
                  <span className="mobile-hide">
                    {item.reviewstatus === 'true' ? langService.Translate('Reviewed') : langService.Translate('Not yet reviewed')}
                  </span>
                </span>
              </>
            )}
          </td>
          <td style={{ width: '5px', padding: 0 }}>
            <span> &gt; </span>
          </td>
        </ListItem>
      );
    }

    if (item.style === 'Note List:')
    {
      return (
        <NoteListItem className="event-list" onClick={this.handleClick}>
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="media">
            <div className="mr-3">
              <h5 className="font-size-14">
                {item.TaskStatus}{' '}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
              </h5>
            </div>
            <div className="media-body">
              <div>{item.TaskTitle}</div>
            </div>
          </div>
        </NoteListItem>
      );
    }

    const badgeClass =
      item.TaskStatus === 'Pending' ? 'badge badge-warning' : 'badge badge-primary';

    return (
      <ListItem onClick={this.handleClick}>
        <td className="text-right" style={{ width: 24 }}>
          <span>
            <i className={item.TaskIcon + ' font-size-24'} />
          </span>
        </td>
        <td>
          <h5 className="text-wrap">
            {isJson(item.TaskTitle) ? JSON.parse(item.TaskTitle)?.name : item.TaskTitle}
          </h5>
          <p className="text-muted mb-0">{item.TaskSubTitle}</p>
        </td>
        <td className="text-right">
          <span className={badgeClass + ' font-size-14'}>{item.TaskStatus}</span>
        </td>
        <td>
          <span> &gt; </span>
        </td>
        {!item.hidelocks && (
          <td>
            <i
              className={
                item.lockstatus ? 'fas fa-lock font-size-18' : 'fas fa-lock-open font-size-18'
              }
            />
          </td>
        )}
      </ListItem>
    );
  }
}
