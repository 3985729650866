import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { LangServices } from '@lainaedge/platformshared';
// import images
import logo from 'Common/assets/images/logo-laina.png';
import vector2 from 'Common/assets/images/vector2.png';
import { myProjectName, myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';

import packageJson from '../../../package.json';


const langService = LangServices.instance();

/**
 * AuthLayout component.
 *
 * @remarks
 * Login Layout for data monitors
 *
 * @component AuthLayout
 * @category Component
 */
const AuthLayout = () =>
{
  const location = useLocation();
  const { versionInfo, title_text, dashTitle } = useData();
  const { error, clearState } = useAuth();


  const capitalizeFirstLetter = (str: string) =>
  {
    return str.charAt(1).toUpperCase() + str.slice(2);
  };

  const currentPage = capitalizeFirstLetter(location.pathname);
  const sanitizedPageTitle = currentPage + ' | V' + versionInfo?.version + ' | ' + title_text;

  const titleParts = dashTitle.split('(');

  const handleClearState = () =>
  {
    clearState();
  };

  const pathName = location.pathname?.toLocaleLowerCase();

  const isAdmin = !pathName.includes('/study') && !pathName.includes('/mobile-login');
  const showForgotPassword = pathName == '/login' || location.pathname.includes('/study/enterpassword');
  const showGoBackLink = pathName == '/forgot-password' || location.pathname.includes('/study/forgotpassword') || location.pathname.includes('/mobile-login');

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sanitizedPageTitle}</title>
        <meta name="title" content={sanitizedPageTitle} />
        <meta property="og:title" content={sanitizedPageTitle} />
      </Helmet>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p className='mb-0'>{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="pt-4">
                  <div className="p-2">
                    {/* {error && error ? <Alert color="danger">{error}</Alert> : null} */}
                    <Outlet />
                    {showGoBackLink && <div className="mt-5 text-center">
                      <p>
                        {langService.Translate('Go back to')}{' '}
                        <Link
                          to={isAdmin ? '/login' : '/study'}
                          className="font-weight-medium text-darkblue"
                          onClick={handleClearState}
                        >
                          {isAdmin ? langService.Translate('Login') : langService.Translate('Study')}
                        </Link>{' '}
                      </p>
                    </div>}
                    {myServerEnv === 'uat' && (
                      <div className="mt-3 login-page-small">
                        This is the User Acceptance Test (UAT) Environment. If you are at the site
                        or looking for production data &nbsp;
                        <a
                          className="login-notice"
                          href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                        >
                          Click Here.{' '}
                        </a>
                      </div>
                    )}

                    {myServerEnv === 'prod' && (
                      <div className="mt-3">This is the Production Server for {dashTitle}</div>
                    )}
                  </div>

                  {showForgotPassword && (
                    <div className="mt-4 text-center">
                      <Link
                        to={isAdmin ? 'forgot-password' : '/study/forgotPassword/1'}
                        className="text-muted"
                        onClick={handleClearState}
                      >
                        <i className="fa-solid fa-lock-keyhole"></i> {langService.Translate('Forgot your password')}?
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
