import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LangServices, StepSection } from '@lainaedge/platformshared';

const langService = LangServices.instance();

/**
 * Props for PageSection component.
 */
export interface PageSectionProps
{
  /** Components to be rendered inside card body.  */
  components: JSX.Element[];
  /** Step data. */
  step: StepSection;
}

/**
 * Used for state for [[`PageSection`]] component
 */
export interface PageSectionState
{
  /** Collapse Open state for foldable section */
  isOpen: boolean;
}

/**
 * PageSection component
 *
 * @component PageSection
 * @category PageElements
 */
export default class PageSection extends Component<PageSectionProps, PageSectionState> {
  /**
   * Constructor for PageSection class component.
   */
  constructor(props: PageSectionProps)
  {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  /**
   * Renders PageSection class component.
   */
  public render(): JSX.Element
  {
    const components = this.props.components;
    const step = this.props.step;

    if (step.options.checkOption('FoldablePrimary'))
    {
      return (
        <div className="foldable alert-primary">
          <div
            className="foldable-title row justify-content-between"
            onClick={() =>
            {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <div>{step.text}</div>
            <div>
              <span className="action-title">{this.state.isOpen ? langService.Translate('Close') : langService.Translate('Expand')}</span>
              <span className="action-icon mr-1">
                <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'} />
              </span>
            </div>
          </div>
          <div className="foldable-content">
            <Collapse isOpen={this.state.isOpen}>
              <div className="pt-2 pb-2">{components}</div>
            </Collapse>
          </div>
        </div>
      );
    }

    if (step.options.checkOption('StickyHeader'))
    {
      return (
        <div className="page-header" data-testid={'pageheader'}>
          {components}
        </div>
      );
    }

    return (
      <div className="page-section" data-testid={'pagesection'}>
        {components}
      </div>
    );
  }
}
