import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { MenuItem } from 'Common/constants/types';
import { useData } from 'Common/context/DataContext';

import SidebarMenuLink from './SidebarMenuLink';

type IntlTooltipProps = 'tooltip_en' | 'tooltip_es' | 'tooltip_hi';
type IntlTitleProps = 'title_en' | 'title_es' | 'title_hi';
type IntlSubtitleProps = 'subtitle_en' | 'subtitle_es' | 'subtitle_hi';

interface SidebarMenuProps
{
  menu: MenuItem;
  index: number;
  collapse: boolean;
  onCollapse: (menuItem: MenuItem | undefined) => void;
}

const SidebarMenu = ({ menu, index, collapse, onCollapse }: SidebarMenuProps) =>
{
  const location = useLocation();
  const { lang } = useData();

  const [isCollapsed, setIsCollapsed] = useState(true);

  function getTooltipPropName(lang: string): IntlTooltipProps
  {
    if (lang === 'es')
    {
      return 'tooltip_es';
    }
    if (lang === 'hi')
    {
      return 'tooltip_hi';
    }
    return 'tooltip_en';
  }

  function getTitlePropName(lang: string): IntlTitleProps
  {
    if (lang === 'es')
    {
      return 'title_es';
    }
    if (lang === 'hi')
    {
      return 'title_hi';
    }
    return 'title_en';
  }

  function getSubtitlePropName(lang: string): IntlSubtitleProps
  {
    if (lang === 'es')
    {
      return 'subtitle_es';
    }
    if (lang === 'hi')
    {
      return 'subtitle_hi';
    }
    return 'subtitle_en';
  }

  const isActive = useMemo(
    () => !!menu.children.find((item: MenuItem) => location.pathname === item.route),
    [location.pathname, menu.children],
  );

  useEffect(() =>
  {
    setIsCollapsed(collapse);
  }, [collapse]);

  const onToggleCollapse = () =>
  {
    setIsCollapsed(!isCollapsed);
    onCollapse(isCollapsed ? menu : undefined);
  };

  return (
    <React.Fragment>
      <li className={!isCollapsed ? 'mm-active' : ''}>
        {menu[getTooltipPropName(lang)] && (
          <UncontrolledTooltip placement="top" target={`tooltip-${index}`}>
            {menu[getTooltipPropName(lang)]}
          </UncontrolledTooltip>
        )}
        <Link
          to="#"
          className={`has-arrow waves-effect ${isActive ? 'mm-active' : ''}`}
          id={`tooltip-${index}`}
          onClick={onToggleCollapse}
        >
          <i className={menu.icon!} />
          <span className="font-size-15">{menu[getTitlePropName(lang)]}</span>
        </Link>
        <ul
          className={`sub-menu mm-collapse ${isCollapsed ? '' : 'mm-show'}`}
          aria-expanded="false"
        >
          {menu.children.map((menuLink, i) => (
            <SidebarMenuLink
              key={i}
              link={menuLink}
              label={menuLink[getSubtitlePropName(lang)] || ''}
              index={`${index}-${i}`}
            />
          ))}
          <hr className="sub-menu-bottom-border" />
        </ul>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenu;
