import React from 'react';

import { StepForm } from '@lainaedge/platformshared';

import FormInput from './FormInput';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * Switch component
 *
 * @component Switch
 * @category FormElements
 */
export default class Switch extends FormInput
{
  /**
   * Toggles the value of a field between 'Yes' or 'No'.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  handleChangeCheckbox = (field: StepForm.FieldInfo) =>
  {
    this.setValue(field, this.state.myFieldValue === 'Yes' ? 'No' : 'Yes');
  };

  /**
   * Renders Switch class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    /** Represents the value of a field as it would be stored in the database. */
    const myValueDatabase = step.getValueDatabase(field.field);

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <div className="custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              id={field.field}
              className="custom-control-input"
              name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
              checked={
                this.isEditFieldOnModal()
                  ? this.props.formProps.edit_values[field.field] === 'Yes'
                  : this.props.formProps.hide_fields[field.field]
                    ? false
                    : myValueDatabase === 'Yes'
              }
              disabled={this.isFieldDisabled()}
              onChange={() =>
              {
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(
                    field,
                    this.props.formProps.edit_values[field.field] === 'Yes' ? 'No' : 'Yes',
                  );
                } else
                {
                  this.handleChangeCheckbox(field);
                }
              }}
            />
            <label
              className="custom-control-label"
              htmlFor={field.field}
            >
              {htmlToReactParser.parse(field.text)}
            </label>
          </div>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
