import React from 'react';
import { InputGroup } from 'reactstrap';

import { LangServices,StepForm, StringOptions } from '@lainaedge/platformshared';
import { LookupType } from '@lainaedge/platformshared/lib/types/DataDictionaryType';
import classNames from 'classnames';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';
import configureMeasurements, { allMeasures } from 'convert-units';

import FormInput from '../FormInput';
import { InputProps, PortalInputOptionsType } from '../types';

import './index.scss';

const convert = configureMeasurements(allMeasures);
const langService = LangServices.instance();

/**
 * MultiUnitInput component
 *
 * @component MultiUnitInput
 * @category FormElements
 */
export default class MultiUnitInput extends FormInput
{
  constructor(props: InputProps)
  {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const unit = this.props.formProps.step.getValueUnits(field.field);
    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.defaultUnit ?? unit,
      myFieldValue: this.getValue(field),
      myUnitFieldValue: this.getValue(field),
      myOzFieldValue: '',
      error: this.props.formProps.errors[field.field],
      warning: this.props.formProps.warnings ? this.props.formProps.warnings[field.field] : '',
    };
  }

  async componentDidUpdate(prevProps: { formProps: PortalInputOptionsType })
  {
    const field = this.props.formProps.field;

    if (
      this.props.formProps.field.field !== prevProps.formProps.field.field ||
      this.props.formProps.errors[field.field] !== prevProps.formProps.errors[field.field] ||
      this.props.formProps.warnings !== prevProps.formProps.warnings
    )
    {
      const field = this.props.formProps.field;
      const unit = this.props.formProps.step.getValueUnits(field.field);

      this.setState({
        myAlign: this.props.formProps.step.getValueAlign(field.field),
        myUnits: this.props.defaultUnit ?? unit,
        myFieldValue: this.getValue(field),
        myUnitFieldValue: this.getValue(field),
        myOzFieldValue: '',
        error: this.props.formProps.errors[field.field],
        warning: this.props.formProps.warnings ? this.props.formProps.warnings[field.field] : '',
      });
    }
  }

  getConversionUnit = () =>
  {
    if (this.state.myUnits == 'lbs')
    {
      return 'lb';
    }
    if (this.state.myUnits == 'mL')
    {
      return 'ml';
    }
    if (this.state.myUnits == 'f')
    {
      return 'F';
    }
    if (this.state.myUnits == 'c')
    {
      return 'C';
    }
    return this.state.myUnits;
  };

  get2DecimalNumber = (num: number) =>
  {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  get5DecimalNumber = (num: number) =>
  {
    return Math.round((num + Number.EPSILON) * 100000) / 100000;
  };

  getReadableUnit = (unit: string) =>
  {
    return unit == 'ml' ? 'mL' : unit;
  };

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  getLookupItemText = (item: any) =>
  {
    const lang = LangServices.currentLanguage.short_code;
    return item['text_' + lang];
  }

  /**
   * Used to set new unit.
   *
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeUnit = (e: any) =>
  {
    const value = e.target.value.toLowerCase();
    const toUnit = value == 'c' ? 'C' : value == 'f' ? 'F' : value;
    const convertedValue = convert(Number(this.state.myFieldValue))
      .from(this.props.defaultUnit)
      .to(toUnit);
    if (this.props.defaultUnit === 'kg')
    {
      const lbValue = Math.floor(convertedValue);
      const ozValue = convert(convertedValue - lbValue)
        .from('lb')
        .to('oz');
      this.setState({
        myUnits: value,
        myUnitFieldValue: this.state.myFieldValue ? lbValue.toString() : '',
        myOzFieldValue: this.state.myFieldValue ? this.get2DecimalNumber(ozValue).toString() : '',
      });
    } else
    {
      this.setState({
        myUnits: value,
        myUnitFieldValue: this.state.myFieldValue
          ? this.get2DecimalNumber(convertedValue).toString()
          : '',
        myOzFieldValue: '',
      });
    }
  };

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeText = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = e.target.value;
    const re = /^[0-9]*\.?[0-9]*$/;
    if (value !== '' && !re.test(value))
    {
      return;
    }
    this.setValue(field, value);
  };

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeUnitText = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = e.target.value;
    const re = /^[0-9]*\.?[0-9]*$/;
    if (value !== '' && !re.test(value))
    {
      return;
    }
    this.setState({
      myUnitFieldValue: value,
    });
    if (value.length > 0)
    {
      if (value.charAt(value.length - 1) === '.') return;
    }
    if (value === '' && this.state.myOzFieldValue === '')
    {
      this.setState({
        myUnitFieldValue: '',
        myFieldValue: '',
      });
      this.props.formProps.step.setValueFromUser(field.field, '');
      return;
    }
    if (isNaN(value))
    {
      return;
    }

    const currentUnit = this.getConversionUnit();
    const metricValue =
      convert(Number(value)).from(currentUnit).to(this.props.defaultUnit) +
      (this.props.defaultUnit == 'kg'
        ? convert(Number(this.state.myOzFieldValue)).from('oz').to(this.props.defaultUnit)
        : 0);

    this.setState({
      myUnitFieldValue: value,
      myFieldValue: this.get5DecimalNumber(metricValue).toString(),
    });
    this.props.formProps.step.setValueFromUser(
      field.field,
      this.get5DecimalNumber(metricValue).toString(),
    );
  };

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeOzText = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = e.target.value;
    const re = /^[0-9]*\.?[0-9]*$/;
    if (value !== '' && !re.test(value))
    {
      return;
    }
    this.setState({
      myOzFieldValue: value,
    });
    if (value.length > 0)
    {
      if (value.charAt(value.length - 1) === '.') return;
    }
    if (value === '' && this.state.myUnitFieldValue === '')
    {
      this.setState({
        myFieldValue: '',
        myOzFieldValue: '',
      });
      this.props.formProps.step.setValueFromUser(field.field, '');
      return;
    }
    if (isNaN(value))
    {
      return;
    }
    const currentUnit = this.getConversionUnit();
    const metricValue =
      convert(Number(value)).from('oz').to(this.props.defaultUnit) +
      convert(Number(this.state.myUnitFieldValue)).from(currentUnit).to(this.props.defaultUnit);

    this.setState({
      myOzFieldValue: value,
      myFieldValue: this.get5DecimalNumber(metricValue).toString(),
    });
    this.props.formProps.step.setValueFromUser(
      field.field,
      this.get5DecimalNumber(metricValue).toString(),
    );
  };

  renderHelpMessage = (currentUnit: string, inactiveUnit: string) =>
  {
    const parsedCurrentUnit = currentUnit == 'c' ? 'C' : currentUnit == 'f' ? 'F' : currentUnit;
    const parsedInactiveUnit = inactiveUnit == 'c' ? 'C' : inactiveUnit == 'f' ? 'F' : inactiveUnit;
    const possibilities = convert().from(parsedCurrentUnit).possibilities();
    if (!possibilities.includes(parsedInactiveUnit))
    {
      return;
    }

    if (parsedInactiveUnit == 'lb')
    {
      const convertedValue = convert(Number(this.state.myFieldValue))
        .from(parsedCurrentUnit)
        .to(parsedInactiveUnit);

      const lbValue = Math.floor(convertedValue);
      const ozValue = convert(convertedValue - lbValue)
        .from('lb')
        .to('oz');

      return (
        <div className="row">
          {this.state.myFieldValue && (
            <div className="multi-unit-description">
              {langService.Translate('This is the same as')} {lbValue} lb {this.get2DecimalNumber(ozValue)} oz
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="row">
        {this.state.myFieldValue && (
          <div className="multi-unit-description">
            {langService.Translate('This is the same as')}{' '}
            {parsedCurrentUnit === this.props.defaultUnit
              ? this.get2DecimalNumber(
                convert(Number(this.state.myFieldValue))
                  .from(this.props.defaultUnit)
                  .to(parsedInactiveUnit),
              )
              : this.get2DecimalNumber(Number(this.state.myFieldValue))}{' '}
            {this.getReadableUnit(
              (parsedCurrentUnit === this.props.defaultUnit
                ? parsedInactiveUnit
                : this.props.defaultUnit) as string,
            )}
          </div>
        )}
      </div>
    );
  };

  /**
   * Renders Lookup class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const fieldDetails = step.tableDef?.getField(field.field);
    const fieldOptions = new StringOptions(fieldDetails?.options);
    const unitLookupName = fieldOptions.checkOption('displayUnits');

    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    let className = 'form-control';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';
    if (this.state.myAlign === 'right') className += ' text-right';

    const currentUnit = this.getConversionUnit();

    if (step.tableDef && step.dataDictionary && unitLookupName)
    {
      /** Represents a lookup table */
      const unitList: LookupType[] = step.dataDictionary.getLookupTable(unitLookupName);

      const inactiveUnit = unitList
        .find((u: any) => u.custom_code !== currentUnit)
        ?.custom_code.toLowerCase();

      const fieldValue = this.isEditFieldOnModal()
        ? this.props.formProps.edit_values[field.field]
        : this.state.myFieldValue;


      return (
        <>
          <>
            <div
              className={classNames(
                'd-inline-block multi-unit-input-field mr-2 mb-1',
                this.isFieldDisabled() ? 'disabled' : '',
              )}
            >
              <InputGroup>
                <span className="input-group-prepend">
                  <select
                    className="form-control multi-unit-select"
                    onChange={this.handleChangeUnit}
                    disabled={this.isFieldDisabled()}
                    value={this.state.myUnits}
                  >
                    {unitList?.map((item: any) => (
                      <option key={item.code} value={item.custom_code.toLowerCase()}>
                        {this.getLookupItemText(item)}
                      </option>
                    ))}
                  </select>
                </span>
                <input
                  id="main-unit-input"
                  className={className}
                  type="text"
                  name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
                  value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
                  disabled={this.isFieldDisabled()}
                  hidden={currentUnit != this.props.defaultUnit}
                  onChange={(e: any) =>
                  {
                    if (this.isEditFieldOnModal())
                    {
                      this.props.formProps.handleChangeEditValues(field, e.target.value);
                    } else
                    {
                      this.handleChangeText(field, e);
                    }
                  }}
                />

                <input
                  id="other-unit-input"
                  className={className}
                  type="text"
                  name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
                  value={
                    [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue)
                      ? ''
                      : this.isEditFieldOnModal()
                        ? this.props.formProps.edit_values[field.field]
                        : this.state.myUnitFieldValue
                  }
                  disabled={this.isFieldDisabled()}
                  hidden={currentUnit == this.props.defaultUnit}
                  onChange={(e: any) =>
                  {
                    if (this.isEditFieldOnModal())
                    {
                      this.props.formProps.handleChangeEditValues(field, e.target.value);
                    } else
                    {
                      this.handleChangeUnitText(field, e);
                    }
                  }}
                />
                <span className="input-group-append multi-unit-append">
                  <span className="input-group-text">
                    <i>{this.state.myUnits}</i>
                  </span>
                </span>
              </InputGroup>
            </div>
            {this.state.myUnits === 'lb' && (
              <div className="d-inline-block oz-input-field mb-1 mr-2">
                <InputGroup>
                  <input
                    id="oz-unit-input"
                    className={className}
                    type="text"
                    name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
                    value={
                      [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue)
                        ? ''
                        : this.isEditFieldOnModal()
                          ? this.props.formProps.edit_values[field.field]
                          : this.state.myOzFieldValue
                    }
                    disabled={this.isFieldDisabled()}
                    hidden={currentUnit == this.props.defaultUnit}
                    onChange={(e: any) =>
                    {
                      if (this.isEditFieldOnModal())
                      {
                        this.props.formProps.handleChangeEditValues(field, e.target.value);
                      } else
                      {
                        this.handleChangeOzText(field, e);
                      }
                    }}
                  />
                  <span className="input-group-append multi-unit-append">
                    <span className="input-group-text">
                      <i>oz</i>
                    </span>
                  </span>
                </InputGroup>
              </div>
            )}
            {this.renderQnsAndUnavailableSwitches()}
          </>
          {![QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) &&
            this.renderHelpMessage(currentUnit, inactiveUnit as string)}
          {this.renderValidationError()}
        </>
      );
    }

    return <></>;
  }
}
