import React, { useState } from 'react';

import { DataRecord } from '@lainaedge/platformshared';

/**
 * Props for [[`LockSwitch`]] component
 */
type Props = {
  /** Database record for the lock */
  record: DataRecord<any>;
  /** Toogles the lock switch. */
  toggleTaskLockStatus: (index: any, old_status: boolean) => Promise<boolean>;
  /** Index number of the item in table/grid */
  index: number;
};

/**
 * LockSwitch component
 *
 * @component LockSwitch
 * @category Component
 */
const LockSwitch = ({ record, toggleTaskLockStatus, index }: Props): JSX.Element =>
{
  const [lockStatus, setLockStatus] = useState(record.data.lock_status);

  const handleToggle = async () =>
  {
    const oldLockStatus = lockStatus;
    setLockStatus(!oldLockStatus);
    const res = await toggleTaskLockStatus(index, oldLockStatus);
    if (res === oldLockStatus)
    {
      setLockStatus(res);
    }
  };

  return (
    <React.Fragment>
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          checked={lockStatus}
          onChange={handleToggle}
        />
        <label className="custom-control-label" onClick={handleToggle}></label>
      </div>
    </React.Fragment>
  );
};

export default LockSwitch;
