import React, { Component } from 'react';
import { Button, Collapse, Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataRecord, LangServices, QueryLog } from '@lainaedge/platformshared';
import classNames from 'classnames';
import moment from 'moment';

import './index.scss';

const langService = LangServices.instance();

/**
 * Props for [[`FieldQueryItem`]] component
 */
export interface FieldQueryItemProps {
  /** Database records to be displayed. */
  records: DataRecord[];
  /** Lock history items to be displayed. */
  lockHistory: DataRecord[];
  /** If set to true renders Reply button. */
  is_edit_mode: boolean;
  is_query_mode: boolean;
  field_name: string;
  showReplyModal: (record: DataRecord) => void;
  acceptQuery: (record: DataRecord) => void;
  closeQuery: (record: DataRecord) => void;
}

/**
 * FieldQueryItem component
 *
 * @component FieldQueryItem
 * @category FormElements
 */
export default class FieldQueryItem extends Component<FieldQueryItemProps> {
  state = {
    isOpen: false,
  };

  constructor(props: FieldQueryItemProps) {
    super(props);
  }

  /**
   * Renders Queries for the field/record.
   */
  renderQueriesTable() {
    const is_edit_mode = this.props.is_edit_mode;
    const is_query_mode = this.props.is_query_mode;

    const lockDates = this.props.lockHistory
      .filter((r: DataRecord) => r.data.event_text.includes(' locked'))
      .map((r: DataRecord) => r.data.event_dt_local);

    return (
      <div className="table-responsive field-query-table">
        <Table className="table table-sm m-0" style={{ borderSpacing: 0 }}>
          <thead>
            <tr>
              <th className="date">{langService.Translate('Date')}</th>
              <th className="user">{langService.Translate('User')}</th>
              <th>{langService.Translate('Query')}</th>
              <th>{langService.Translate('Response')}</th>
              <th>{langService.Translate('Status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.records.map((record: DataRecord) => {
              return (
                <tr
                  key={record.id}
                  className={classNames(
                    record.data.query_status === QueryLog.QueryStatus.Open ? 'red-border' : '',
                    moment(record.data.event_dt_local).isAfter(lockDates[lockDates.length - 1])
                      ? 'light-red-bg'
                      : '',
                  )}
                >
                  <th className="date" scope="row">
                    {moment(record.data.event_dt_local).format('YYYY-MM-DD')}
                  </th>
                  <td className="user">{record.data.reported_by}</td>
                  <td className="text-break">{record.data.message}</td>
                  <td className="text-break">{record.data.response}</td>
                  <td>
                    {record.data.query_status === QueryLog.QueryStatus.Open &&
                      langService.Translate('Open')}
                    {record.data.query_status === QueryLog.QueryStatus.Answered &&
                      langService.Translate('Answered')}
                    {record.data.query_status === QueryLog.QueryStatus.Resolved &&
                      langService.Translate('Resolved')}
                    {record.data.query_status === QueryLog.QueryStatus.Deleted &&
                      langService.Translate('Closed')}
                    {record.data.query_status === QueryLog.QueryStatus.NotResolved &&
                      langService.Translate('Not Resolved')}
                  </td>
                  <td>
                    {is_query_mode && record.data.query_status === QueryLog.QueryStatus.Open && (
                      <Button
                        className="save-btn btn-sm mr-1"
                        type="button"
                        color="primary"
                        onClick={() => this.props.closeQuery(record)}
                      >
                        {langService.Translate('Close')}
                      </Button>
                    )}
                    {is_edit_mode && record.data.query_status === QueryLog.QueryStatus.Open && (
                      <Button
                        className="save-btn btn-sm"
                        type="button"
                        color="primary"
                        onClick={() => this.props.showReplyModal(record)}
                      >
                        {langService.Translate('Reply')}
                      </Button>
                    )}
                    {is_query_mode &&
                      record.data.query_status === QueryLog.QueryStatus.Answered && (
                        <Button
                          className="save-btn btn-sm mr-1"
                          type="button"
                          color="primary"
                          onClick={() => this.props.acceptQuery(record)}
                        >
                          {langService.Translate('Accept')}
                        </Button>
                      )}
                    {is_query_mode &&
                      record.data.query_status === QueryLog.QueryStatus.Answered && (
                        <Button
                          className="save-btn btn-sm"
                          type="button"
                          color="primary"
                          onClick={() => this.props.showReplyModal(record)}
                        >
                          {langService.Translate('Review')}
                        </Button>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  /**
   * Renders FieldQueryItem class component.
   */
  public render(): JSX.Element {
    const openQueries = this.props.records.filter((record: DataRecord) => {
      return record.data.query_status === QueryLog.QueryStatus.Open;
    });

    const wrapperClassname = openQueries.length
      ? 'record-foldable open-queries'
      : 'record-foldable closed-queries';

    if (this.props.field_name == 'FORM') {
      return this.renderQueriesTable();
    }

    if (openQueries.length) {
      return (
        <div className={'col-md-12'}>
          <div className={wrapperClassname}>
            <div className="foldable-title row justify-content-between align-items-center mr-1">
              <div>
                <b>{langService.Translate('There is an open query for this field')}</b>
              </div>
            </div>
            <div className="foldable-content">{this.renderQueriesTable()}</div>
          </div>
        </div>
      );
    }

    return (
      <div className={'col-md-12'}>
        <div className={wrapperClassname}>
          <div
            className="foldable-title row justify-content-between align-items-center mr-1"
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <div>
              <b>
                {langService.Translate(
                  `There has been ${this.props.records.length} queries on this field`,
                )}
              </b>
            </div>
            <div>
              <span className="action-title">
                {this.state.isOpen
                  ? langService.Translate('Close Query History')
                  : langService.Translate('Show Query History')}
              </span>
              <span className="action-icon mr-1">
                <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-down' : 'chevron-right'} />
              </span>
            </div>
          </div>
          <div className="foldable-content">
            <Collapse isOpen={this.state.isOpen}>{this.renderQueriesTable()}</Collapse>
          </div>
        </div>
      </div>
    );
  }
}
