import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { LicenseManager } from 'ag-grid-enterprise';
import { AuthProvider } from 'Common/context/AuthContext';
import { DataProvider } from 'Common/context/DataContext';
import toastr from 'toastr';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'toastr/build/toastr.min.css';
import './i18n';

/** Ag Grid Enterprise key setup */
LicenseManager.setLicenseKey(
  'CompanyName=Brians Com LLC,LicensedGroup=Protovate-1,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-033265,SupportServicesEnd=26_November_2023_[v2]_MTcwMDk1NjgwMDAwMA==e06890fd3dc491144a33daab1bbf3843',
);

/** To handle the Buffer is not defined error on console */
(window as any).global = window;
window.Buffer = window.Buffer || require('buffer').Buffer;

/** Toastr options setup */
toastr.options = {
  closeButton: true,
  debug: true,
  newestOnTop: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '1500',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const app = (
  <BrowserRouter>
    <CookiesProvider>
      <AuthProvider>
        <DataProvider>
          <App />
        </DataProvider>
      </AuthProvider>
    </CookiesProvider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
