import React, { useState } from 'react';
import { Alert, Label, UncontrolledTooltip } from 'reactstrap';

import { DataTools, LangServices } from '@lainaedge/platformshared';
import { useAuth } from 'Common/context/AuthContext';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import toastr from 'toastr';
import * as Yup from 'yup';

const langService = LangServices.instance();
const DEMO_PID_PASSWORD = 'laina1234';

/**
 * Authentication/pages/CreateMagicLink component.
 *
 * @remarks
 * Page where you can create a new magic link
 *
 * @component CreateMagicLink
 * @category Page
 */
const CreateMagicLink = (): JSX.Element =>
{
  const { error } = useAuth();
  const [magicLink, SetMagicLink] = useState('');

  async function handleValidSubmit(values: { participantId: string; targetPage: string })
  {
    const testValue2 = {
      Pid: values.participantId, // '9995-594-362'
      Password: DEMO_PID_PASSWORD,
      Page: values.targetPage, // 'page/participant_start/9995-594-362'
    };
    const stringValue = DataTools.encodeObjectCompressed(testValue2);

    SetMagicLink(window.location.origin + `/system/magicPage?code=${stringValue}`);
  }

  const copyTextToClipboard = (text: string) =>
  {
    navigator.clipboard.writeText(text);
    toastr.success(langService.Translate('Magic Link Copied to Clipboard'), 'Success');
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        participantId: '',
        targetPage: '',
      }}
      validationSchema={Yup.object().shape({
        participantId: Yup.string().required(langService.Translate('Enter Participant ID')),
        targetPage: Yup.string().required(langService.Translate('Enter Target Page Link')),
      })}
      onSubmit={(values) =>
      {
        handleValidSubmit(values);
      }}
    >
      {({ errors, touched }) => (
        <Form className="form-horizontal">
          {error && error ? <Alert color="danger">{error}</Alert> : null}
          <div className="form-group">
            <Label for="participantId" className="form-label">
              {langService.Translate('Participant ID')}
            </Label>
            <Field
              name="participantId"
              id="participantId"
              type="input"
              placeholder={langService.Translate('Enter Participant ID')}
              className={
                'form-control' +
                (errors.participantId && touched.participantId ? ' is-invalid' : '')
              }
            />
            <ErrorMessage
              name="participantId"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group">
            <Label for="targetPage" className="form-label">
              {langService.Translate('Target Page Link')}
            </Label>
            <Field
              name="targetPage"
              id="targetPage"
              type="input"
              placeholder={langService.Translate('Enter Target Page Link')}
              className={
                'form-control' +
                (errors.targetPage && touched.targetPage ? ' is-invalid' : '')
              }
            />
            <ErrorMessage
              name="targetPage"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="mt-3">
            <button
              className="btn btn-login btn-block waves-effect waves-light save-btn"
              type="submit"
            >
              {langService.Translate('Create Link')}
            </button>
          </div>

          {magicLink && (
            <div className="mt-3">
              <span
                role="button"
                id="magic-link"
                onClick={() => copyTextToClipboard(magicLink)}
              >
                {magicLink}
              </span>
              <UncontrolledTooltip placement="top" target={`magic-link`}>
                {langService.Translate('You can copy the link by clicking on it')}
              </UncontrolledTooltip>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CreateMagicLink;
