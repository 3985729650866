import React from 'react';

import { LangServices, StepForm } from '@lainaedge/platformshared';
import { LookupType } from '@lainaedge/platformshared/lib/types/DataDictionaryType';
import { printEDCLog } from 'Common/helpers';

import FormInput from './FormInput';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * Boolean component
 *
 * @component Boolean
 * @category FormElements
 */
export default class Boolean extends FormInput {
  /**
   * Used to change the value of a radio field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   */
  handleChangeRadio = (field: StepForm.FieldInfo, e: any) => {
    this.setValue(field, e.target.value);
  };

  /**
   * Returns the lookup item's text for in selected language
   *
   * @param item - Lookup item
   * @returns {string}
   */
  getLookupItemText = (item: any) => {
    const lang = LangServices.currentLanguage.short_code;
    return item['text_' + lang];
  };

  /**
   * Renders Boolean class component.
   */
  public render(): JSX.Element {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    /** Represents a text formatted version of the field value */
    const myValueText = step.getValueFormatted(field.field);
    const myValueDatabase = step.getValueDatabase(field.field);

    // printEDCLog('Boolean render - db value :', myValueText, '-', myValueDatabase)

    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;
    const checkboxList: LookupType[] | undefined =
      step.dataDictionary?.getLookupTable('YesNoBoolean');

    let className = 'form-control custom-control-input';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';

    // printEDCLog('Boolean render - checkboxList :', checkboxList);

    return (
      <>
        {checkboxList
          ? checkboxList.map((item: any) => (
              <div
                className="custom-control custom-radio boolean-checkbox mb-3 mr-3"
                key={this.isEditFieldOnModal() ? 'edit_value' + item.code : field.field + item.code}
              >
                <input
                  type="radio"
                  id={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field + item.code
                      : field.field + item.code
                  }
                  className="custom-control-input"
                  name={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field
                      : this.isEditMode()
                      ? 'm' + field.field
                      : field.field
                  }
                  value={item.english}
                  checked={
                    this.isEditFieldOnModal()
                      ? this.props.formProps.edit_values[field.field] === item.english
                      : this.props.formProps.hide_fields[field.field]
                      ? false
                      : myValueText === item.english
                  }
                  disabled={this.isFieldDisabled()}
                  onChange={(e: any) => {
                    if (this.isEditFieldOnModal()) {
                      this.props.formProps.handleChangeEditValues(field, e.target.value);
                    } else {
                      this.handleChangeRadio(field, e);
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field + item.code
                      : field.field + item.code
                  }
                >
                  {htmlToReactParser.parse(this.getLookupItemText(item))}
                </label>
              </div>
            ))
          : ''}
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
