import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from 'Common/context/AuthContext';

/**
 * Admin/pages/DefaultPage component.
 *
 * @remarks
 * DefaultPage that handles redirect to the default url from config
 *
 * @component DefaultPage
 * @category Page
 */
const DefaultPage = (): JSX.Element =>
{
  const { defaultRoute } = useAuth();
  const navigate = useNavigate();

  useEffect(() =>
  {
    if (defaultRoute)
    {
      navigate(defaultRoute);
    }
  }, [navigate, defaultRoute]);

  return <React.Fragment></React.Fragment>;
};

export default DefaultPage;
